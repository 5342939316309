import { getSysTime } from '@/services/menu';
// import * as Icons from '@ant-design/icons';

import Authorized from '@/utils/Authorized';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';

const { check } = Authorized;

// Conversion router to menu.
function formatter(data, parentAuthority, parentName) {
  return data
    .map(item => {
      if (!item.name || !item.path) {
        return null;
      }

      // 如果有module值，使用module值进行国际化
      let locale;
      if (item.module) {
        locale = `module.${item.module}`;
      } else {
        // 没有module值，使用menu+name进行嵌套国际化
        // eslint-disable-next-line no-lonely-if
        if (parentName) {
          locale = `${parentName}.${item.name}`;
        } else {
          locale = `menu.${item.name}`;
        }
      }
      const result = {
        ...item,
        // name: formatMessage({ id: locale, defaultMessage: item.name }),
        name: item.name,
        locale,
        authority: item.authority || parentAuthority,
      };
      if (item.routes) {
        const children = formatter(item.routes, item.authority, locale);
        // Reduce memory usage
        result.children = children;
      }
      delete result.routes;
      return result;
    })
    .filter(item => item);
}

const memoizeOneFormatter = memoizeOne(formatter, isEqual);

/**
 * get SubMenu or Item
 */
const getSubMenu = item => {
  // doc: add hideChildrenInMenu
  if (item.children && !item.hideChildrenInMenu && item.children.some(child => child.name)) {
    return {
      ...item,
      children: filterMenuData(item.children), // eslint-disable-line
    };
  }
  return item;
};

/**
 * filter menuData
 */
const filterMenuData = menuData => {
  if (!menuData) {
    return [];
  }
  return menuData
    .filter(item => (item.name || item.module) && !item.hideInMenu)
    .map(item => check(item.authority, getSubMenu(item)))
    .filter(item => item);
};
/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 */
const getBreadcrumbNameMap = menuData => {
  const routerMap = {};

  const flattenMenuData = data => {
    data.forEach(menuItem => {
      if (menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      // Reduce memory usage
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  return routerMap;
};

const memoizeOneGetBreadcrumbNameMap = memoizeOne(getBreadcrumbNameMap, isEqual);

// const toHump = (name) => name.replace(/-(\w)/g, (_, letter) => letter.toUpperCase());

// const addIcon2Menu = (menu) => {
//   menu.forEach((item) => {
//     const { icon } = item;
//     if (icon) {
//       const iconName = toHump(icon.replace(icon[0], icon[0].toUpperCase()));
//       const newIcon = Icons[icon] || Icons[''.concat(iconName, 'Outlined')];
//       if (newIcon) {
//         try {
//           // eslint-disable-next-line no-param-reassign
//           item.icon = React.createElement(newIcon);
//         } catch (err) {
//           // eslint-disable-next-line no-console
//           console.warn('failed parse menu icon', err, icon);
//         }
//       }
//     }
//   });

//   return menu;
// };

const MenuModel = {
  namespace: 'menu',
  state: {
    menuData: [],
    systime: '',
  },
  effects: {
    *getMenu({ payload }, { put }) {
      const { routes, authority } = payload;
      const menuData = filterMenuData(memoizeOneFormatter(routes, authority));
      // 暂时没有使用
      // eslint-disable-next-line no-unused-vars
      const breadcrumbNameMap = memoizeOneGetBreadcrumbNameMap(menuData);
      yield put({
        type: 'saveMenu',
        payload: menuData,
      });
    },
    *fetchSysTime(_, { call, put }) {
      const response = yield call(getSysTime);
      yield put({
        type: 'timing',
        payload: response,
      });
    },
  },
  reducers: {
    saveMenu(state, action) {
      return { ...state, menuData: action.payload || [] };
    },
    timing(state, action) {
      return {
        ...state,
        systime: action.payload.content,
      };
    },
  },
};
export default MenuModel;
