export default {
  'header.guest': 'Invité',
  'header.welcome': "Salut l'ami",
  'header.modifyPassword': 'Modifier le mot de passe',
  'header.logout': 'Se déconnecter',
  'header.read': 'Lire les données',
  'header.timing': "Synchronisation de l'appareil",
  'header.report': 'Signaler',
  'header.history': "Données d'historique",
  'header.chart': 'Tableau statistique',
  'header.fullScreen': 'Passer en plein écran',
  'header.theme': 'Basculer le thème',
  'header.theme.light': 'Léger',
  'header.theme.dark': 'Sombre',
  'header.component.download': 'Conducteur',

  'header.password.original': "Mot de passe d'origine",
  'header.password.original.placeholder': "Veuillez saisir le mot de passe d'origine",
  'header.password.new': 'nouveau mot de passe',
  'header.password.new.placeholder': 'Veuillez saisir un nouveau mot de passe',
  'header.password.confirm': 'Confirmez le mot de passe',
  'header.password.confirm.placeholder': 'Veuillez entrer Confirmer le mot de passe',
  'header.password.notMatch':
    'Les deux entrées de mot de passe sont différentes, veuillez confirmer',
  'header.password.same':
    "Le mot de passe d'origine est le même que le nouveau mot de passe, il n'est donc pas nécessaire de modifier le mot de passe",
  'header.password.blank': 'Le mot de passe ne peut pas être un ou des caractères vides',

  'header.password.modify.success':
    'Mot de passe mis à jour, rediriger vers la connexion après 5 secondes',

  'header.device.connected': 'Appareil connecté',
  'header.device.disconnected': 'Appareil déconnecté',
  'header.modifyEmail': 'Modifier votre email',
};
