export default {
  'plan.title': '巡檢計劃',
  'plan.title.special': '按次計劃設置',
  'plan.btn.add': '增加計劃',
  'plan.btn.addTemporary': '增加臨時計劃',
  'plan.btn.bind': '綁定設備',
  'plan.btn.copy': '複製計劃',
  'plan.btn.paste': '粘貼計劃',
  'plan.btn.export': '導出計劃',
  'plan.btn.batch': '批量日程',
  'plan.btn.addshift': '增加日程',
  'plan.btn.delshift': '刪除日程',
  'plan.btn.guard': '設置人員',
  'plan.btn.addrest': '增加休息日',
  'plan.btn.Manu': '手動綁定',
  'plan.modal.label.next': '次日',
  'plan.modal.label.onetime': '巡檢一次用時（分鐘）',
  'plan.modal.label.resttime': '每次間休時間（分鐘）',
  'plan.modal.label.no': '第 ',
  'plan.modal.label.day': ' 日',
  'plan.modal.label.weekrest': '每週休息日',
  'plan.modal.label.specialrest': '特殊休息日',
  'plan.modal.label.period': '巡檢週期',
  'plan.modal.label.unit': '單位',
  'plan.modal.label.plantimes': '計劃次數',
  'plan.modal.label.times': '次',
  'plan.modal.label.cost': '每圈用時',
  'plan.modal.label.interval': '兩圈間隔',
  'plan.modal.label.month': '月',
  'plan.modal.label.week': '周',
  'plan.modal.label.minute': '分鐘',
  'plan.modal.label.hour': '小時',
  'plan.modal.label.days': '日',
  'plan.table.schedule': '日程',
  'plan.table.restday': '休息日',
  'plan.table.monday': '週一',
  'plan.table.tuesday': '週二',
  'plan.table.wednesday': '週三',
  'plan.table.thursday': '週四',
  'plan.table.friday': '週五',
  'plan.table.saturday': '週六',
  'plan.table.sunday': '週日',
  'plan.table.type': '計劃類型',
  'plan.table.per': '每 ',
  'plan.table.month': ' 個月',
  'plan.table.patrol': '巡檢',
  'plan.tab.plan': '普通計劃',
  'plan.tab.special': '按次計劃',
  'plan.tab.schedule': '巡檢日程',
  'plan.step.basic': '基礎信息設置',
  'plan.step.site': '巡檢地點設置',
  'plan.step.shift': '巡檢日程設置',
  'plan.step.next': '下一步',
  'plan.step.prev': '上一步',
  'plan.step.submit': '提交',
  'plan.step.rest': '休息日設置',
  'plan.transfer.site.select': '已選地點',
  'plan.transfer.site.unselect': '待選地點',
  'plan.input.plan.placeholder': '請輸入計劃名稱',
  'plan.notification.description.save.success': '計劃保存成功',
  'plan.notification.description.sitefirst': '請先設置巡檢地點',
  'plan.notification.description.schedulefirst': '請先設置巡檢日程',
  'plan.notification.description.exitplan':
    '此計劃存在已經生成的當天數據，是否需要覆蓋當天數據，重新生成數據？',
  'plan.notification.description.than24': '單次巡檢用時不能超過24小時，請重新設置',
  'plan.notification.description.repeat': '班次時間段有重合，請重新設置',
  'plan.notification.description.date': '結束時間必須大於開始時間',
  'plan.notification.description.exitdate': '選擇的日期已存在，請重新選擇',
  'plan.notification.description.exitdevice': '當前設備已存在，請勿重複添加',
  'plan.notification.description.appuser': '請設置巡檢人員',
  'plan.notification.description.copy.success': '計劃複製成功',
  'plan.notification.description.copyfirst': '請先複製計劃',
  'plan.notification.description.selfirst': '請先選擇要複製的計劃',
  'plan.notification.description.selone': '請選擇一條記錄進行操作',
  'plan.notification.description.patrolone': '巡檢次數爲一次，間隔時間必須設置爲零',
  'plan.Modal.confirm.paste': '確定要將複製的計劃粘貼到當前區域下嗎？',
  'plan.Modal.confirm.restday': '請先選擇特殊休息日',
  'plan.shift.day.wrong': '日計劃，最多隻能創建31天，請重新輸入巡檢週期！',
  'plan.shift.week.wrong': '周計劃，最多隻能創建4周，請重新輸入巡檢週期！',
  'plan.shift.month.wrong': '月計劃，最多隻能創建3個月，請重新輸入巡檢週期！',
  'plan.rest.wrong': '不能全設置爲週休息日，請重新設置',
  'plan.bin.title': '綁定已有計劃',
  'plan.bin.wrong': '該計劃已被其他臨時計劃綁定，無法執行此操作',
  'plan.bin.tooltip': '被綁定的計劃將停止生成巡檢報告，直到該臨時計劃結束',
};
