export default {
  'site.title': '巡檢地點',
  'site.btn.batch': '批量讀取',
  'site.btn.add': '增加地點',
  'site.btn.addgps': '增加GPS地點',
  'site.btn.addcoll': '增加採集地點',
  'site.btn.edit': '編輯地點',
  'site.btn.del': '刪除地點',
  'site.btn.import': '匯入地點',
  'site.btn.export': '匯出地點',
  'site.btn.download': '下載數據',
  'site.btn.assign': '分配內容',
  'site.btn.move': '移動地點',
  'site.btn.rnd': '隨機分配地點',
  'site.btn.rnd.del': '刪除所有地點',
  'site.modal.position': '地理位置',
  'site.table.code': '地點卡號',
  'site.table.name': '地點名稱',
  'site.table.type': '地點類型',
  'site.table.longitude': '經度',
  'site.table.latitude': '緯度',
  'site.table.range': '誤差(米)',
  'site.table.voice': '配置語音',
  'site.table.site': '地理位置',
  'site.table.app.content': '內容標題',
  'site.table.app.des': '內容描述',
  'site.table.app.submission': '提交方式',
  'site.table.app.qr': '二維碼點',
  'site.table.app.bluetooth': '藍芽',
  'site.table.app.em': 'EM卡',
  'site.table.app.single': '單選',
  'site.table.app.multiple': '多選',
  'site.table.app.enter': '輸入',
  'site.table.app.colluser': '採集人員',
  'site.table.app.colltime': '採集時間',
  'site.table.voice.clear': '清除設備語音',
  'site.table.voice.clear.success': '語音清除成功',
  'site.table.voice.clear.failed': '語音清除失敗',
  'site.table.voice.clear.confirm': '確定要刪除已經下載到設備中的語音嗎？',
  'site.table.edit': '編輯',
  'site.input.site.placeholder': '請輸入地點名稱',
  'site.input.code.placeholder': '請輸入地點卡號',
  'site.Transfer.Unselected': '待選語音',
  'site.Transfer.selected': '已選語音',
  'site.headerTitle.GPS': 'GPS地點列表',
  'site.headerTitle.All': '已配置語音及GPS地點列表',
  'site.notification.filtercode': '已經過濾掉地點卡號重複的記錄',
  'site.notification.filtername': '已經過濾掉地點名稱重複的記錄',
  'site.notification.download.success': '數據下載成功',
  'site.notification.mostsite': '選擇的普通地點數量最多不能超過1000個，請重新選擇',
  'site.notification.mostgpssite': '選擇的GPS地點數量最多不能超過500個，請重新選擇',
  'site.notification.delposition': '確定要刪除地圖中的地點座標嗎？',
  'site.table.moveup': '上移地點',
  'site.table.movedown': '下移地點',
  'site.hasBindPlan.delconfirm': '待刪除的地點已綁定了巡檢計劃，是否仍然刪除？',
};
