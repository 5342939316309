export default {
  'book.title': 'Контакты',
  'book.btn.add': 'Добавить',
  'book.btn.import': 'Импорт',
  'book.btn.export': 'Экспорт',
  'book.table.name': 'Имя',
  'book.table.tel': 'Номер',
  'book.input.name': 'Введите имя контакта.',
  'book.input.tel': 'Введите номер телефона.',
  'book.table.moveup': 'Вверх.',
  'book.table.movedown': 'Вниз!',
};
