export default {
  'dashboard.charts': '图表',
  'dashboard.charts.title.trend': '合格率趋势',
  'dashboard.charts.title.rank': '合格率排行榜',
  'dashboard.charts.dept.placeholder': '请选择区域',
  'dashboard.charts.rank.dept': '区域',
  'dashboard.charts.rank.plan': '计划',
  'dashboard.charts.rank.guard': '人员',
  'dashboard.charts.rank.site': '地点',
  'dashboard.charts.date.today': '本日',
  'dashboard.charts.date.week': '本周',
  'dashboard.charts.date.month': '本月',
  'dashboard.charts.orderby.asc': '升序',
  'dashboard.charts.orderby.desc': '降序',
  'dashboard.statistics.type': '类型',
  'dashboard.statistics.type.qualified': '合格',
  'dashboard.statistics.type.unqualified': '漏巡',
  'dashboard.statistics.type.todo': '待巡',
  'dashboard.statistics.columnes.count': '数量',
  'dashboard.statistics.columnes.rate': '占比',
  'dashboard.statistics.title.percent': '今日合格率',
  'dashboard.latestHistories.columnes.site': '地点',
  'dashboard.latestHistories.columnes.guard': '人员/设备',
  'dashboard.latestHistories.columnes.info': '事件',
  'dashboard.latestHistories.columnes.remark': '备注',
  'dashboard.latestHistories.columnes.time': '时间',
  'dashboard.latestHistories.columnes.extra': '媒体',
  'dashboard.latestHistories.title.trend': '近期数据上传趋势',
  'dashboard.latestAlarms.title.alarmData': '近期报警',
  'dashboard.latestLogs.columnes.action': '界面 & 操作',
  'dashboard.latestLogs.columnes.time': '时间',
  'dashboard.latestLogs.columnes.reader': '设备',
  'dashboard.latestLogs.columnes.user': '用户',
  'dashboard.latestLogs.title.log': '近期日志',
  'dashboard.card.extra.refresh': '刷新数据',
  'dashboard.card.extra.history': '跳转到历史数据界面',
  'dashboard.card.extra.alarm': '跳转到报警数据界面',
  'dashboard.card.extra.log': '跳转到日志数据界面',
};
