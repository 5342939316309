export default {
  'comm.1': 'Unsupported function',
  'comm.4096': 'Unknown reader',
  'comm.4097': 'Device not found',
  'comm.4098': 'Failed to write',
  'comm.4099': 'Failed to read device',
  'comm.4100': 'Failed to open device',
  'comm.4101': 'Failed to connect',
  'comm.4102': 'Wrong device type',
  'comm.4104': 'File name is blank',
  'comm.4105': 'Create file failed',
  'comm.4150': 'The database is full, please delete the fingerprint before the collection',
  'comm.4500': 'Please configure voice first and generate voice library file',
};
