export default {
  'site.title': 'Сайт проверки',
  'site.btn.batch': 'Пакетное чтение',
  'site.btn.add': 'Добавить сайт',
  'site.btn.addgps': 'Добавить местоположение GPS',
  'site.btn.addcoll': 'Добавить сайт коллекции',
  'site.btn.edit': 'Редактировать сайт',
  'site.btn.del': 'Удалить сайт',
  'site.btn.import': 'Импорт сайта',
  'site.btn.export': 'Экспорт сайта',
  'site.btn.download': 'загрузить данные',
  'site.btn.assign': 'Назначить содержимое',
  'site.btn.move': 'Переместить местоположение',
  'site.btn.rnd': 'Назначать сайты случайным образом',
  'site.btn.rnd.del': 'Удалить все контрольные точки',
  'site.modal.position': 'Местоположение',
  'site.table.code': 'номер карточки сайта',
  'site.table.name': 'имя сайта',
  'site.table.type': 'тип сайта',
  'site.table.longitude': 'долгота',
  'site.table.latitude': 'Широта',
  'site.table.range': 'Ошибка (метры)',
  'site.table.voice': 'Настроить голос',
  'site.table.site': 'место',
  'site.table.app.content': 'Название контента',
  'site.table.app.des': 'Описание контента',
  'site.table.app.submission': 'Метод отправки',
  'site.table.app.qr': 'Точка QR-кода',
  'site.table.app.bluetooth': 'Bluetooth',
  'site.table.app.em': 'EM-карта',
  'site.table.app.single': 'Единственный выбор',
  'site.table.app.multiple': 'Множественный выбор',
  'site.table.app.enter': 'Ввод',
  'site.table.app.colluser': 'Коллектор',
  'site.table.app.colltime': 'Время сбора',
  'site.table.voice.clear': 'Очистить голос устройства',
  'site.table.voice.clear.success': 'Очистка голоса успешно завершена',
  'site.table.voice.clear.failed': 'Ошибка очистки голоса',
  'site.table.voice.clear.confirm':
    'Вы уверены, что хотите удалить голоса, уже загруженные на устройство? ',
  'site.table.edit': 'Изменить',
  'site.input.site.placeholder': 'Пожалуйста, введите заполнитель',
  'site.input.code.placeholder': 'Пожалуйста, введите номер карты местоположения',
  'site.Transfer.Unselected': 'Не выбранный голос',
  'site.Transfer.selected': 'Выбранный голос',
  'site.headerTitle.GPS': 'Список местоположений GPS',
  'site.headerTitle.All': 'Список настроенных местоположений для голосовой связи и GPS',
  'site.notification.filtercode': 'Отфильтрованы записи с повторяющимися номерами карточек сайтов',
  'site.notification.filtername': 'Записи с повторяющимися именами сайтов были отфильтрованы',
  'site.notification.download.success': 'Загрузка данных выполнена успешно',
  'site.notification.mostsite':
    'Максимальное количество выбранных общих сайтов не может превышать 1000, выберите еще раз',
  'site.notification.mostgpssite':
    'Количество выбранных сайтов GPS не может превышать 500, выберите еще раз',
  'site.notification.delposition':
    'Вы уверены, что хотите удалить координаты местоположения с карты? ',
  'site.table.moveup': 'Вверх.',
  'site.table.movedown': 'Вниз!',
  'site.hasBindPlan.delconfirm':
    'Место, подлежащее удалению, привязано к плану инспекции, и все еще удаляется?',
};
