export default {
    'module.1': '巡检设置',
    'module.101': '区域设置',
    'module.10101': '新增区域',
    'module.10102': '编辑区域',
    'module.10103': '删除区域',
    'module.10104': '查看区域',
    'module.102': '事件设置',
    'module.10201': '增加事件',
    'module.10202': '编辑事件',
    'module.10203': '删除事件',
    'module.10204': '查看菜单',
    'module.10205': '导入事件',
    'module.10206': '导出事件',
    'module.10207': '批量读取',
    'module.103': '人员设置',
    'module.10301': '增加人员',
    'module.10302': '编辑人员',
    'module.10303': '删除人员',
    'module.10304': '查看菜单',
    'module.10305': '导入人员',
    'module.10306': '导出人员',
    'module.10307': '批量读取',
    'module.10308': '采集指纹',
    'module.10309': '编辑指纹',
    'module.10310': '下载指纹',
    'module.104': '地点设置',
    'module.10401': '增加地点',
    'module.10402': '编辑地点',
    'module.10403': '删除地点',
    'module.10404': '查看菜单',
    'module.10405': '导入地点',
    'module.10406': '下载数据',
    'module.10407': '批量读取',
    'module.10408': '分配内容',
    'module.10409': '导出地点',
    'module.105': '计划设置',
    'module.10501': '增加计划',
    'module.10502': '编辑巡检计划',
    'module.10503': '删除巡检计划',
    'module.10504': '查看菜单',
    'module.10505': '绑定设备',
    'module.10506': '导出计划',
    'module.106': '巡检设备注册',
    'module.10601': '设备注册',
    'module.10602': '删除设备',
    'module.10604': '查看菜单',
    'module.107': '通讯录设置',
    'module.10701': '增加通讯录',
    'module.10702': '编辑通讯录',
    'module.10703': '删除通讯录',
    'module.10704': '查看菜单',
    'module.10706': '导入通讯录',
    'module.10705': '导出通讯录',
    'module.108': '回执信息',
    'module.10801': '增加回执信息',
    'module.10802': '编辑回执信息',
    'module.10803': '删除回执信息',
    'module.10804': '查看菜单',
    'module.10805': '导入回执信息',
    'module.10806': '导出回执信息',
    'module.109': '下载数据',
    'module.10901': '下载数据',
    'module.10904': '查看菜单',
    'module.110': '巡检内容',
    'module.11001': '增加内容',
    'module.11002': '编辑内容',
    'module.11003': '删除内容',
    'module.11004': '查看菜单',
    'module.11005': '选择地点',
    'module.111': '对讲设置',
    'module.11101': '新增组',
    'module.11102': '编辑组',
    'module.11103': '删除组',
    'module.11104': '查看菜单',
    'module.112': '语音设置',
    'module.11204': '查看菜单',
    'module.113': '下载数据',
    'module.11301': '下载数据',
    'module.11304': '查看菜单',
    'module.114': '电子围栏',
    'module.11401': '增加围栏',
    'module.11402': '编辑围栏',
    'module.11403': '删除围栏',
    'module.11404': '查看菜单',
    'module.2': '基础操作',
    'module.201': '读取数据',
    'module.20104': '读取数据',
    'module.202': '历史数据',
    'module.20201': '导出历史数据',
    'module.20203': '重新分析',
    'module.20204': '查看菜单',
    'module.203': '巡检报告',
    'module.20301': '导出巡检报告',
    'module.20304': '查看菜单',
    'module.204': '统计图表',
    'module.20401': '导出统计数据',
    'module.20404': '查看菜单',
    'module.205': '漏检数据',
    'module.20501': '导出漏检数据',
    'module.20504': '查看菜单',
    'module.206': '报警数据',
    'module.20601': '导出报警数据',
    'module.20603': '删除报警数据',
    'module.20604': '查看菜单',
    'module.207': '日志数据',
    'module.20701': '导出日志数据',
    'module.20704': '查看菜单',
    'module.208': '清除设备数据',
    'module.20804': '清除设备数据',
    'module.209': '设备校时',
    'module.20904': '设备校时',
    'module.210': '清除设备指纹',
    'module.21004': '清除设备指纹',
    'module.211': '实时监控',
    'module.21101': '编辑点位',
    'module.21102': '轨迹回放',
    'module.21104': '查看菜单',
    'module.21105': '查看实时数据',
    'module.212': '原始数据',
    'module.21201': '导出原始数据',
    'module.21202': '未知卡转换',
    'module.21204': '查看菜单',
    'module.213': '巡检日历',
    'module.21304': '查看菜单',
    'module.214': '考勤数据',
    'module.21401': '导出考勤数据',
    'module.21404': '查看菜单',
    'module.215': '巡检明细',
    'module.21501': '导出巡检明细',
    'module.21504': '查看菜单',
    'module.3': '数据维护',
    'module.302': '巡检设备列表',
    'module.30201': '激活设备',
    'module.30202': '添加设备',
    'module.30203': '删除设备',
    'module.30204': '查看菜单',
    'module.30205': '设备替换',
    'module.30206': '参数设置',
    'module.30207': '修改备注',
    'module.303': '系统参数设置',
    'module.30304': '系统参数设置',
    'module.304': '设备参数设置',
    'module.30404': '查看设备参数设置',
    'module.30408': '闹铃设置',
    'module.305': '通讯参数设置',
    'module.30504': '查看通讯参数设置',
    'module.306': '系统权限设置',
    'module.30601': '增加角色',
    'module.30602': '编辑角色信息',
    'module.30603': '删除角色信息',
    'module.30604': '查看菜单',
    'module.307': '操作员管理',
    'module.30701': '增加操作员',
    'module.30702': '编辑操作员',
    'module.30703': '删除操作员',
    'module.30704': '查看菜单',
    'module.30705': '查看区域',
    'module.30706': '权限分配',
    'module.30708': '修改密码',
    'module.308': '设备初始化',
    'module.30804': '设备初始化',
    'module.5': '工作台',
    'module.216': 'BI可视化大屏',
    'module.21604': '查看菜单',
}