export default {
  confirm: 'Конечно',
  cancel: 'Отмена',
  back: 'возврат',
  'general.homeSwitch.title.old':
    'Не привыкли к текущей домашней странице, вернуться к старой версии? ',
  'general.homeSwitch.title.new': 'Хотите попробовать обновленную домашнюю страницу? ',
  'general.WePatrol.qr.download.text': 'Отсканируйте, чтобы загрузить и открыть приложение Patrol',
  'general.WePatrol.qr.use.text':
    'Используйте [{code}] в качестве регистрационного кода для регистрации учетной записи и входа в систему',
  'general.WePatrol.qr.login.text':
    'Отсканируйте QR-код, чтобы зарегистрировать учетную запись и войти в систему',
  'общее.гостевое.предупреждение':
    'В настоящее время в режиме посетителя отображаемые данные не являются реальными демонстрационными данными моделирования, сгенерированными в реальном времени, только для просмотра...',
  'general.unhandled.alert':
    'В текущей системе есть {count} фрагментов данных, ожидающих обработки и анализа, проявите терпение...',
};
