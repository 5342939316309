export default {
  'alarm.notification.title': 'Напоминание о тревоге',
  'alarm.notification.desc':
    'Получать {count} данные о тревоге, пожалуйста,обратите внимание на обработку...',

  'alarm.notification.view': 'Просмотреть подробности',
  'alarm.notification.ignore': 'Игнорировать тревогу',

  'alarm.notification.list': 'Ожидающие тревоги',
  'alarm.notification.readAll': 'Вся обработка',
  'alarm.notification.readAll.confirm': 'Обрабатывать ли все тревоги? ',

  'alarm.notification.read.title': 'Уведомление',
  'alarm.notification.read.done': 'Тревога отмечена для обработки',
  'alarm.notification.read.error': 'Произошла ошибка при обработке тревоги',

  'alarm.manual': 'Ручная тревога',
  'alarm.low': 'Тревога о низком заряде батареи',
  'alarm.omit': 'Отсутствует будильник',
  'alarm.tilt': 'Тревога наклона',
  'alarm.motionless': 'Неподвижная тревога',
  'alarm.collision': 'Тревога о столкновении',
  'alarm.dismantle': 'Тревога при открытии оболочки',

  'alarm.choice.no': 'Нет оповещений тревогах',
  'alarm.choice.web': 'Только уведомления на веб-странице',
  'alarm.choice.app': 'Только тревоги',
  'alarm.choice.all': 'Веб-страница и мобильные оповещения',
  'alarm.notification.title.desc': 'содержит ли веб - сайт или КПК функцию напоминания',
  'alarm.out': 'Выйти из машины',
  'alarm.in': 'Сигнализация на входе',
};
