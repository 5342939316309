export default {
  'query.condition.dept': 'Επωνυμία Εταιρίας',
  'query.condition.guard': 'Ονομασία Φύλακα',
  'query.condition.site': 'Ονομασία Σημείου Ελέγχου',
  'query.condition.event': 'Ονομασία Συμβάντος',
  'query.condition.plan': 'Ονομασία Πλάνου',
  'query.condition.planguard': 'Πλάνο Φυλάκων',
  'query.condition.device': 'Κωδικός Συσκευής',
  'query.condition.begin': 'Ώρα Έναρξης',
  'query.condition.end': 'Ώρα Λήξης',
  'query.condition.time': 'Χρόνος Περιπολίας',
  'query.condition.alarmtype': 'Τύπος Συναγερμού',
  'query.input.placeholder.site': 'Παρακαλώ επιλέξτε σημείο ελέγχου',
  'query.input.placeholder.guard': 'Παρακαλώ επιλέξτε φύλακα',
  'query.input.placeholder.device': 'Παρακαλώ επιλέξτε συσκευή',
  'query.input.placeholder.plan': 'Παρακαλώ επιλέξτε Πλάνο',
  'query.input.placeholder.alarm': 'Παρακαλώ επιλέξτε τύπο συναγερμού',
  'query.history.title': 'Δεδομένα Ιστορικού',
  'query.history.btn.reanalysis': 'Επανανάλυση',
  'query.app.remark': 'Περιεχόμενα και Σημειώσεις',
  'query.app.multimedia': 'Multimedia',
  'query.app.view': 'Απεικόνιση',
  'query.app.notes': 'Σημειώσεις',
  'query.app.view.pict': 'Εμφάνιση φωτογραφιών',
  'query.app.view.video': 'Εμφάνιση Video',
  'query.app.view.audio': 'Ακρόαση ήχου',
  'query.app.options': 'Επιλογές περιεχομένων',
  'query.app.state': 'Κατάσταση',
  'query.app.notentered': 'Δεν έχουν εισαχθεί',
  'query.app.notselected': 'Μη επιλεγμένα',
  'query.app.selected': 'Επιλεγμένα',
  'query.app.site.errspan':
    'Το τρέχον εύρος σφάλματος σημείου είναι εκτός του προκαθορισμένου. Το σημείο ελέγχου μπορεί να μην είναι έγκυρο.',
  'query.app.site.time': 'Η τρέχουσα ώρα περιπολίας μπορεί να έχει τροποποιηθεί.',
  'query.app.site.both':
    'Η τρέχουσα περιοχή σφαλμάτων σημείων πέρα από την καθορισμένη περιοχή, το σημείο ελέγχου μπορεί να μην είναι έγκυρο σημείο ελέγχου και ο τρέχοντος χρόνος περιπολίας μπορεί να έχει τροποποιηθεί',
  'query.alarm.title': 'Δεδομένα Συναγερμού',
  'query.alarm.btn.del': 'Διαγραφή δεδομένων',
  'query.alarm.type.all': 'Όλοι οι συναγερμοί',
  'query.alarm.type.manual': 'Χειροκίνητος συναγερμός',
  'query.alarm.type.lowvoltage': 'Συναγερμός χαμηλής τάσης',
  'query.alarm.type.incline': 'Συναγερμός κλίσης',
  'query.alarm.type.static': 'Συναγερμός Ακινησίας',
  'query.alarm.type.impact': 'Συναγερμός πρόσκρουσης',
  'query.alarm.type.open': 'Συναγερμός κατεδάφισης',
  'query.alarm.info': 'Πληροφορίες Συναγερμών',
  'query.alarm.time': 'Ώρα συναγερμού',
  'query.alarm.position': 'Θέση Συναγερμού',
  'query.omit.title': 'Δεδομένα Παράληψης',
  'query.omit.rangetime': 'Από Προς',

  'query.chart.title': 'Στατιστικά',
  'query.chart.table.plantimes': 'Χρόνοι Πλάνου',
  'query.chart.table.arrivedtimes': 'Χρόνοι Αφίξεων',
  'query.chart.table.omittimes': 'Χρόνοι Παράλειψης',
  'query.chart.table.arrivedrate': 'Ρυθμός αφίξεων',
  'query.chart.table.omitrate': 'Ρυθμός Παράλειψης',
  'query.chart.table.summary': 'Περίληψη δεδομένων',
  'query.chart.tab.data': 'Στατιστικά Δεδομένα',
  'query.chart.tab.chart': 'Απεικόνιση Διαγραμμάτων',
  'query.chart.tab.chart.column': 'Διαγράμματα με στήλες',
  'query.chart.tab.chart.bar': 'Διαγράμματα Διαγράμματα με γραμμές',
  'query.chart.tab.chart.line': 'Γραφήματα γραμμών',
  'query.chart.tab.omit': 'Δεδομένα Παράλειψης',
  'query.chart.condition.type': 'Τύπος Στατιστικών',
  'query.chart.condition.dept': 'Στατιστικά ανά εταιρία',
  'query.chart.condition.guard': 'Στατιστικά ανά φύλακα',
  'query.chart.condition.site': 'Στατιστικά ανά σημείο ελέγχου',
  'query.chart.condition.plan': 'Στατιστικά ανά πλάνο',

  'query.raw.title': 'Αρχικά δεδομένα',
  'query.raw.table.card': 'ID Κάρτας',
  'query.raw.table.type': 'Τύπος Κάρτας',
  'query.raw.table.name': 'Ονομασία Κάρτας',
  'query.raw.table.unknow': 'Άγνωστη κάρτα',
  'query.raw.table.event': 'Κάρτα Συμβάντων',
  'query.raw.table.guard': 'Κάρτα Φύλακα',
  'query.raw.table.site': 'Κάρτα Σημείου Ελέγχου',
  'query.raw.table.time': 'Ώρα Ελέγχου',
  'query.raw.btn.unknow': 'Άγνωστη μετατροπή κάρτας',
  'query.raw.btn.site': 'Μετατροπή σε σημείο ελέγχου',
  'query.raw.btn.guard': 'Μετατροπή σε κάρτα φύλακα',
  'query.raw.btn.event': 'Μετρατροπή σε κάρτα συμβάντος',

  'query.log.title': 'Πληροφορίες Ιστορικού',
  'query.log.table.operate': 'Interface Χειρισμού',
  'query.log.table.content': 'Περιεχόμενα Χειρισμού',
  'query.log.table.time': 'Χρόνος Χειρισμού',
  'query.log.table.user': 'Χειριστής',
  'query.log.form.dept': 'Ρυθμίσεις Εταιρίας',
  'query.log.form.guard': 'Ρυθμίσεις Φύλακα',
  'query.log.form.site': 'Ρυθμίσεις Σημείου ελέγχου',
  'query.log.form.plan': 'Ρυθμίσεις Πλάνου',
  'query.log.form.read': 'Ανάγνωση δεδομένων',

  'query.calendar.january': 'Ιανουάριος',
  'query.calendar.february': 'Φεβρουάριος',
  'query.calendar.march': 'Μάρτιος',
  'query.calendar.april': 'Απρίλιος',
  'query.calendar.may': 'Μάϊος',
  'query.calendar.june': 'Ιούνιος',
  'query.calendar.july': 'Ιούλιος',
  'query.calendar.august': 'Αύγουστος',
  'query.calendar.september': 'Σεπτέμβριος',
  'query.calendar.october': 'Οκτώβριος',
  'query.calendar.november': 'Νοέμβριος',
  'query.calendar.december': 'Δεκέμβριος',
  'query.calendar.year': '-',
  'query.calendar.year1': ' ',
  'query.calendar.month': ' ',

  'query.report.condition.type': 'Τύπος δεδομένων',
  'query.report.condition.all': 'Όλα τα δεδομένα',
  'query.report.condition.normal': 'Κανονικά Δεδομένα',
  'query.report.condition.arrived': 'Φτάστε στα δεδομένα',
  'query.report.condition.todo': 'Δεδομένα προς ενέργεια',
  'query.report.table.alltimes': 'Όλες οι φορές',
  'query.report.table.patroltimes': 'Ώρες περιπολίας',
  'query.report.table.topatrol': 'Σε ώρες περιπολίας ',
  'query.report.table.sequence.right': 'Σωστή ακολουθία',
  'query.report.table.sequence.err': 'Σφάλμα ακολουθίας',
  'query.report.btn.export.summary': 'Εξαγωγή περιληπτικής αναφοράς',
  'query.report.btn.export.ordinary': 'Εξαγωγή τακτικής αναφοράς',
  'query.report.btn.export.summaryname': 'Περιληπτική αναφορά δεδομένων',
  'query.report.btn.export.ordinaryname': 'Αναφορά κανονικών δεδομένων',
  'query.report.table.step': 'Βήμα',
  'query.report.btn.del': 'Διαγραφή δεδομένων αναφοράς',

  'query.history.analysis': 'Επιβεβαίωση ανάλυσης δεδομένων?',
  'query.content.fold': 'Κλείσιμο όλων',
  'query.content.expand': 'Άνοιγμα όλων',
  'query.report.table.status': 'Τύπος επιθεώρησης',
};
