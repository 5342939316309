export default {
  'guard.title': 'События',
  'guard.btn.batch': 'Считать метки',
  'guard.btn.add': 'Добавить сотрудника',
  'guard.btn.edit': 'Изменить сотрудника',
  'guard.btn.del': 'Удалить сотрудника',
  'guard.btn.import': 'Импорт',
  'guard.btn.export': 'Экспорт',
  'guard.table.code': 'ID сотрудника',
  'guard.table.name': 'Сотрудник',
  'guard.table.operate': 'Операция',
  'guard.table.edit': 'Изменить',
  'guard.app.device': 'список устройств',
  'guard.app.placeholder': 'Пожалуйста, выберите информацию об устройстве',
  'guard.finger.name': 'имя отпечатка',
  'guard.finger.coll': 'Собирать отпечатки пальцев',
  'guard.finger.coll.success': 'Сбор отпечатков пальцев выполнен успешно',
  'guard.finger.coll.failed': 'Не удалось собрать отпечатки пальцев',
  'guard.finger.download': 'Загрузить отпечаток пальца',
  'guard.finger.download.success': 'Загрузка отпечатка успешно завершена',
  'guard.finger.download.failed': 'Не удалось загрузить отпечаток пальца',
  'guard.finger.download.deldata':
    'Перед загрузкой отпечатка пальца удалите данные на устройстве, загрузка отпечатка не удалась',
  'guard.finger.download.nofinger':
    'Информация об отпечатке пальца для загрузки отсутствует, сначала введите отпечаток пальца, а затем загрузите данные',
  'guard.input.guard.placeholder': 'Пожалуйста, введите имя человека',
  'guard.finger.onlyOneLine': 'изменить только одну строку',
  'guard.table.moveup': 'Вверх.',
  'guard.table.movedown': 'Вниз!',
};
