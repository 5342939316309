export default {
  'alarm.notification.title': 'Alarm Notification',
  'alarm.notification.desc': '{count} alarm(s) received,please check...',

  'alarm.notification.view': 'Details',
  'alarm.notification.ignore': 'Ignore',

  'alarm.notification.list': 'Unread Alarms',
  'alarm.notification.readAll': 'Read All',
  'alarm.notification.readAll.confirm': 'Confirm to read all alarms?',

  'alarm.notification.read.title': 'Notice',
  'alarm.notification.read.done': 'The alarm has been processed',
  'alarm.notification.read.error': 'Failed to handle alarm(s)',

  'alarm.manual': 'Manual Alarm',
  'alarm.low': 'Low Power Alarm',
  'alarm.omit': 'Omit Alarm',
  'alarm.tilt': 'Tilt Alarm',
  'alarm.motionless': 'Motionless Alarm',
  'alarm.collision': 'Collision Alarm',
  'alarm.dismantle': 'Dismantle Alarm',
  'alarm.out': 'Loop alarm',
  'alarm.in': 'Ring alarm',

  'alarm.choice.no': 'No alarm prompt',
  'alarm.choice.web': 'Web page alert only',
  'alarm.choice.app': 'Hand-held alarm only',
  'alarm.choice.all': 'Web and handheld alerts',
  'alarm.notification.title.desc':
    'Whether the alarm data reminding function is included in the web page or hand-held terminal',
};
