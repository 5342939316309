import request from '@/utils/request';
import { stringify } from 'qs';

export async function getSocketVersion(deviceType) {
  return request(`/api/socketupgrade/${deviceType}`);
}

export async function getSysTime() {
  return request(`/api/system/getSystemCurrentDate`);
}

export async function getSysTimeByDeviceId(deviceId) {
  return request(`/api/system/systemCurrentDate/${deviceId}`);
}

export async function handleData(params) {
  return request(`/api/HandleData?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function template(params) {
  return request(`/api/system/document?${stringify(params)}`);
}
