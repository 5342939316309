export default {
  'enclosure.title': '电子围栏',
  'enclosure.table.name': '围栏名称',
  'enclosure.table.name.rule': '请输入围栏名称',
  'enclosure.table.timeRange': '时间范围',
  'enclosure.input.timeRange.add': '添加时间',
  'enclosure.btn.add': '增加围栏',
  'enclosure.warn.updateAndNotSave': '围栏修改后未保存设置，是否仍关闭？',
  'enclosure.btn.clear': '清空围栏',
  'enclosure.clear.confirm': '确定要清空围栏数据吗？',
  'enclosure.btn.del': '删除围栏',
  'enclosure.del.confirm': '确定要删除围栏数据吗？',
  'enclosure.btn.currentSite': '当前位置',
  'enclosure.table.btn.fence': '围栏',
  'enclosure.warn.timeRange.coincide': '时间范围重合，请重新设置',
  'enclosure.warn.timeRange.empty': '请选择时间',
  'enclosure.warn.timeRange.notCheck': '时间段不能为空',
};
