export default {
  'guard.title': '巡检人员',
  'guard.btn.batch': '批量读取',
  'guard.btn.add': '增加人员',
  'guard.btn.edit': '编辑人员',
  'guard.btn.del': '删除人员',
  'guard.btn.import': '导入人员',
  'guard.btn.export': '导出人员',
  'guard.table.code': '人员卡号',
  'guard.table.name': '人员名称',
  'guard.table.operate': '操作',
  'guard.table.edit': '编辑',
  'guard.app.device': '设备列表',
  'guard.app.placeholder': '请选择设备信息',
  'guard.finger.name': '指纹名称',
  'guard.finger.coll': '采集指纹',
  'guard.finger.coll.success': '指纹采集成功',
  'guard.finger.coll.failed': '指纹采集失败',
  'guard.finger.download': '下载指纹',
  'guard.finger.download.success': '指纹下载成功',
  'guard.finger.download.failed': '指纹下载失败',
  'guard.finger.download.deldata': '下载指纹前，请先删除设备中数据，指纹下载失败',
  'guard.finger.download.nofinger': '没有要下载的指纹信息，请先录入指纹再下载数据',
  'guard.input.guard.placeholder': '请输入人员名称',
  'guard.finger.onlyOneLine': '只能同时编辑一行',
  'guard.table.moveup': '上移人员',
  'guard.table.movedown': '下移人员',
};
