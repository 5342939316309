import { getDevices, setDevices } from '@/utils/profile';

const CommModel = {
  namespace: 'communication',
  state: {
    // 设备通讯socket状态
    // TODO: 暂时没什么用处，因为可以通过deviceSocket.connected来判断是否已连接
    deviceSocket: null,
    // 当前usb连接的设备
    device: null,
    registeredDevices: [],
    socketVersion: null,
  },
  reducers: {
    changeDeviceSocketStatus(state, { payload }) {
      return {
        ...state,
        deviceSocket: payload,
      };
    },
    changeDeviceStatus(state, { payload }) {
      return {
        ...state,
        device: payload,
      };
    },
    changeSocketVersion(state, { payload }) {
      return {
        ...state,
        socketVersion: payload,
      };
    },
    // 调用此方法前必须保证已经更新过本地的devices存储，比如使用了静默登录接口等；
    initRegisteredDevices(state) {
      return {
        ...state,
        registeredDevices: getDevices(),
      };
    },
    updateRegisteredDevices(state, { payload }) {
      setDevices(payload);
      return {
        ...state,
        registeredDevices: payload,
      };
    },
  },
};
export default CommModel;
