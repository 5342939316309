const AreaModel = {
  namespace: 'area',
  state: {
    areaData: [],
  },
  reducers: {
    saveArea(state, { payload }) {
      return { ...state, areaData: payload || [] };
    },
  },
};
export default AreaModel;
