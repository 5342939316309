import defaultSettings from '../../config/defaultSettings';
import { getTheme, setTheme } from '../utils/profile';

const updateColorWeak = (colorWeak) => {
  const root = document.getElementById('root');

  if (root) {
    root.className = colorWeak ? 'colorWeak' : '';
  }
};

const SettingsModel = {
  namespace: 'settings',
  state: {
    ...defaultSettings,
    theme: getTheme(),
    themebuttonvisible: true,
  },
  reducers: {
    changeSetting(state = defaultSettings, { payload }) {
      const { colorWeak, contentWidth } = payload;

      if (state.contentWidth !== contentWidth && window.dispatchEvent) {
        window.dispatchEvent(new Event('resize'));
      }

      updateColorWeak(!!colorWeak);
      return { ...state, ...payload };
    },

    changeTheme(state = defaultSettings, { payload }) {
      setTheme(payload);
      return {
        ...state,
        theme: payload,
      };
    },

    setAppName(state = defaultSettings, { payload }) {
      return {
        ...state,
        title: payload,
      };
    },
  },
};
export default SettingsModel;
