import {
  loadClock,
  addClock,
  delClock,
  addSound,
  delSound,
  updateSound,
  setsysparam,
} from '@/services/device';

const DeviceModel = {
  namespace: 'device',
  state: {
    list: [],
  },
  effects: {
    *fetchClock(_, { call, put }) {
      const response = yield call(loadClock);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *addClock({ payload, callback }, { call }) {
      try {
        const response = yield call(addClock, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
    *addSound({ payload, callback }, { call }) {
      try {
        const response = yield call(addSound, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
    *delClock({ payload, callback }, { call }) {
      try {
        const response = yield call(delClock, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
    *uptSound({ payload, callback }, { call }) {
      try {
        const response = yield call(updateSound, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
    *delSound({ payload, callback }, { call }) {
      try {
        const response = yield call(delSound, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
    *saveSysSet({ payload, callback }, { call }) {
      try {
        const response = yield call(setsysparam, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        callback(false, e);
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, list: action.payload || [] };
    },
  },
};
export default DeviceModel;
