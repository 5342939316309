// 权限命名规范（暂定）：
// r - Route 代表 路由权限
// b - Button 代表 操作按钮权限

const Auths = {
  rSetup: '1',

  rDept: '10104',
  bDeptAdd: '10101',
  bDeptEdit: '10102',
  bDeptDelete: '10103',

  rEvent: '10204',
  bEventAdd: '10201',
  bEventEdit: '10202',
  bEventDelete: '10203',
  bEventExport: '10205',

  rGuard: '10304',
  bGuardAdd: '10301',
  bGuardEdit: '10302',
  bGuardDelete: '10303',
  bGuardExport: '10305',

  rSite: '10404',
  bSiteAdd: '10401',
  bSiteEdit: '10402',
  bSiteDelete: '10403',
  bSiteExport: '10405',

  rPlan: '10504',
  bPlanAdd: '10501',
  bPlanEdit: '10502',
  bPlanDelete: '10503',
  bPlanExport: '10506',

  rContent: '11004',
  rBook: '10704',
  rReceipt: '10804',
  // rDownGuard: '11304',
  rDownParam: '10904',
  rTalk: '11104',
  rVoice: '11204',
  rDuty: '11304',
  rEnclosure: '11404',
  bRegisterDevice: '10604',

  rBasic: '2',
  rHistory: '20204',
  rReport: '20304',
  rChart: '20404',
  rOmit: '20504',
  rAlarm: '20604',
  rLog: '20704',
  rRealMap: '21104',
  rRaw: '21204',
  rCalendar: '21304',
  rAttendance: '21404',
  rDetail: '21504',
  // bReadData: '30503',
  bReadData: '20104',
  bClearData: '20804',
  // bTiming: '30501',
  bTiming: '20904',

  rData: '3',
  rDevice: '30204',
  rSysParam: '30304',
  rDbBackup: '30305', // 数据库备份权限
  rDeviceParam: '30404',
  rRole: '30604',
  rUser: '30704',
  bConn: '30504',
  bFinger: '21004',
  bInit: '30804',
  // bRegisterDevice: '30502',

  rDashboard: '5',

  // BI大屏权限
  rBI: '21604',
};

export const guestAuths = [
  // 首页权限
  Auths.rDashboard,

  // 一级菜单权限
  Auths.rSetup,
  Auths.rBasic,
  Auths.rData,

  // 巡检设置基础模块 -- 区域
  Auths.rDept,
  Auths.bDeptAdd,
  Auths.bDeptEdit,
  Auths.bDeptDelete,

  // 巡检设置基础模块 -- 事件
  Auths.rEvent,
  Auths.bEventAdd,
  Auths.bEventEdit,
  Auths.bEventDelete,
  // Auths.bEventExport,

  // 巡检设置基础模块 -- 巡检人员
  Auths.rGuard,
  Auths.bGuardAdd,
  Auths.bGuardEdit,
  Auths.bGuardDelete,
  // Auths.bGuardExport,

  // 巡检设置基础模块 -- 地点
  Auths.rSite,
  Auths.bSiteAdd,
  Auths.bSiteEdit,
  Auths.bSiteDelete,
  // Auths.bSiteExport,

  // 巡检设置基础模块 -- 计划
  Auths.rPlan,
  Auths.bPlanAdd,
  Auths.bPlanEdit,
  Auths.bPlanDelete,
  // Auths.bPlanExport,

  // 数据查询模块
  Auths.rHistory, // 历史数据
  Auths.rReport, // 巡检报告
  Auths.rOmit, // 漏检数据
  Auths.rAlarm, // 报警数据
  Auths.rLog, // 日志数据
  // Auths.rCalendar,

  // 系统管理模块
  Auths.rRole, // 角色设置
  Auths.rUser, // 操作员设置
  // Auths.rRealMap,
  // Auths.rSysParam,
  // Auths.rDeviceParam,
  Auths.rBI, // BI大屏幕展示
];

export default Auths;
