export default {
  'guard.title': '巡檢人員',
  'guard.btn.batch': '批量讀取',
  'guard.btn.add': '增加人員',
  'guard.btn.edit': '編輯人員',
  'guard.btn.del': '刪除人員',
  'guard.btn.import': '匯入人員',
  'guard.btn.export': '匯出人員',
  'guard.table.code': '人員卡號',
  'guard.table.name': '人員名稱',
  'guard.table.operate': '操作',
  'guard.table.edit': '編輯',
  'guard.app.device': '設備列表',
  'guard.app.placeholder': '請選擇設備信息',
  'guard.finger.name': '指紋名稱',
  'guard.finger.coll': '採集指紋',
  'guard.finger.coll.success': '指紋採集成功',
  'guard.finger.coll.failed': '指紋採集失敗',
  'guard.finger.download': '下載指紋',
  'guard.finger.download.success': '指紋下載成功',
  'guard.finger.download.failed': '指紋下載失敗',
  'guard.finger.download.deldata': '下載指紋前，請先刪除設備中數據，指紋下載失敗',
  'guard.finger.download.nofinger': '沒有要下載的指紋信息，請先錄入指紋再下載數據',
  'guard.input.guard.placeholder': '請輸入人員名稱',
  'guard.finger.onlyOneLine': '只能同時編輯一行',
  'guard.table.moveup': '上移人員',
  'guard.table.movedown': '下移人員',
};
