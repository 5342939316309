export default {
  'role.title': 'Role',
  'role.btn.add': 'Add Role',
  'role.btn.save': 'Save Role',
  'role.btn.home': 'Return Home',
  'role.table.name': 'Role Name',
  'role.table.operate': 'Operate',
  'role.table.edit': 'Edit',
  'role.input.name.placeholder': 'Please input role name',
  'role.notification.module.description': 'Please select module first',
  'role.notification.role.description': 'Please select role first',
};
