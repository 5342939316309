export default {
  'common.table.batchdel': 'Пакетное удаление',
  'common.table.del': 'удалить',
  'common.table.moveup': 'Переместить записи вверх',
  'common.table.movedown': 'Переместить записи вниз',
  'common.table.cancel': 'Отменить выбор',
  'common.table.selected': 'Выбрано',
  'common.table.item': 'элемент',
  'common.btn.success': 'Операция прошла успешно',
  'common.btn.download.success': 'Загрузка данных прошла успешно',
  'common.btn.download.failed': 'Ошибка загрузки данных',
  'common.btn.confirm': 'Подтвердить',
  'common.btn.cancel': 'Отмена',
  'common.btn.close': 'Закрыть',
  'common.btn.template': 'скачать шаблон',
  'common.btn.import.label': 'Выберите файл импорта',
  'common.btn.export.excel': 'Экспорт формата Excel',
  'common.btn.export.pdf': 'Экспорт в формате Pdf',
  'common.btn.import.title': 'Нажмите, чтобы загрузить',
  'common.btn.home': 'Возврат домой',
  'common.message.prompt': 'подсказка',
  'common.placeholder.message': 'Пожалуйста, введите',
  'common.device.nodata':
    'В устройстве нет данных, пожалуйста, перезагрузите после считывания карты',
  'common.notification.description.batchsuccess':
    'Пакетное чтение выполнено успешно, количество прочитанных записей:',
  'common.notification.description.records': 'Количество записей, введенных на этот раз:',
  'common.notification.description.nodata':
    'Нет данных, соответствующих условиям, пожалуйста, повторно выберите условия запроса',
  'common.notification.description.required': 'Этот элемент обязателен',
  'common.notification.query.range':
    'Диапазон времени запроса не может превышать 60 дней, пожалуйста, повторно выберите условия запроса',
  'common.websocket.version':
    'Текущая версия драйвера относительно низкая. Пожалуйста, загрузите последнюю версию!',
};
