export default {
  'header.guest': 'Επισκέπτης',
  'header.welcome': 'Γειά σας',
  'header.modifyPassword': 'Αλλαγή κωδικού',
  'header.logout': 'Αποσύνδεση',
  'header.read': 'Ανάγνωση δεδομένων',
  'header.timing': 'Ρύθμιση ώρας συσκευής',
  'header.report': 'Αναφορά',
  'header.history': 'Δεδομένα Ιστορικού',
  'header.chart': 'Διάγραμμα στατιστικών',
  'header.fullScreen': 'Εναλλαγή πλήρους οθόνης',
  'header.theme': 'Εναλλαγή θέματος',
  'header.theme.light': 'Φωτεινό',
  'header.theme.dark': 'Σκοτεινό',
  'header.component.download': 'Οδηγός συσκευής',

  'header.password.original': 'Αρχικός Κωδικός',
  'header.password.original.placeholder': 'Παρακαλώ εισάγετε αρχικό κωδικό',
  'header.password.new': 'Νέος Κωδικός',
  'header.password.new.placeholder': 'Παρακαλώ εισάγετε νέο κωδικό',
  'header.password.confirm': 'Επιβεβαίωση κωδικού',
  'header.password.confirm.placeholder': 'Παρακαλώ εισάγετε κωδικό επιβεβαίωσης',
  'header.password.notMatch': 'Οι δύο κωδικοί είναι διαφορετικοί, παρακαλώ επιβεβαιώστε',
  'header.password.same':
    'Ο αρχικός κωδικός πρόσβασης είναι ο ίδιος με τον νέο κωδικό πρόσβασης, επομένως δεν χρειάζεται να αλλάξετε τον κωδικό πρόσβασης',
  'header.password.blank': 'Ο κωδικός δεν μπορεί να έχει κενό χαρακτήρα (ες)',

  'header.password.modify.success':
    'Ο κωδικός πρόσβασης άλλαξε, ανακατευθυνθείτε στη σύνδεση μετά από 5 δευτερόλεπτα',

  'header.device.connected': 'Η συσκευή έχει συνδεθεί',
  'header.device.disconnected': 'Η συσκευή έχει αποσυνδεθεί',
  'header.modifyEmail': 'Τροποποίηση της διεύθυνσης ηλεκτρονικού ταχυδρομείου',
};
