export default {
  // 表格
  'company.table.index': 'Αρ',
  'company.table.buy.count': 'Ποσότητα αγορών',
  'company.table.remain.count': 'Ποσότητα που απομένει',
  'company.table.term': 'Χρήση ώρας',
  'company.table.buy.time': 'Χρόνος Αγοράς',
  'company.table.status': 'Κατάσταση',
  'company.table.device.type': 'Τύπος Συσκευής',
  'company.table.operate': 'Λειτουργία',
  'company.table.name': 'Επωνυμία Εταιρίας',
  'company.table.code': 'Κωδικός Εταιρίας',
  'company.table.software.version': 'Έκδοση Λογισμικού',
  'company.table.begin.date': 'Ώρα Έναρξης',
  'company.table.end.date': 'Ώρα Λήξης',
  'company.table.sales.man': 'Πωλητής',
  'company.table.device.model': 'Μοντέλο συσκευής',
  'company.add.phone.app.login': 'Σύνδεση στην εφαρμογή για κινητά',
  'company.table.device.count': 'Αριθμός συσκευής',
  'company.table.expire.query': 'Λήξη αναζήτησης',
  'company.table.date.filer': 'Φιλτράρισμα ημερομηνίας',
  // 企业
  'company.info.list': 'Λίστα Εταιριών',
  'company.save': 'Αποθήκευση',
  'company.yes': 'Ναι',
  'company.no': 'Όχι',
  'company.edit': 'Επεξεργασία',
  'company.version.all': 'Όλες οι εκδόσεις',
  'company.version.formal': 'Επίσημη έκδοση',
  'company.version.try': 'Δοκιμαστική έκδοση',
  'company.version.demo': 'Έκδοση επίδειξης',
  'company.version.network': 'Δικτυακή έκδοση',
  'company.version.free': 'Ελεύθερη έκδοση',
  'company.all': 'όλες οι εταιρίες',
  'company.soon.expire': 'Λήγει σύντομα',
  'company.already.expire': 'έχει λήξει',
  'company.adding': 'Προστίθεται...',
  'company.add.fail': 'Αποτυχία προσθήκης εταιρίας',
  'company.changing': 'Γίνεται επεξεργασία...',
  'company.change.fail': 'Απέτυχε η τροποποίηση της εταιρίας',
  'company.btn.add': 'Προσθήκη εταιρίας',
  'company.btn.card': 'Πληροφορίες κάρτας επαναφόρτισης',
  'comany.btn.delete': 'Διαγραφή εταρίας',
  'company.delete.tips': 'Θέλετε να διαγράψετε την εγγραφή;',
  'company.reset.password': 'Επαναφορά κωδικού',
  'company.reset.password.select': 'Παρακαλώ επιλέξτε αρχείο για επαναφορά κωδικού πρώτα',
  'company.reset.password.success': 'Επιτυχής επαναφορά κωδικού',
  'company.renewal.customer': 'Ανανέωση πληροφοριών πελάτη',
  'company.register.device.all': 'Λίστα Συσκευών',
  'company.register.device.soon': 'Πληροφορίες συσκευής που λήγουν',
  'company.register.device.expire': 'Πληροφορίες συσκευής που έχουν λήξει',
  'company.register.company.count': 'Αριθμός εγγεγραμμένων επιχειρήσεων',
  'company.register.soon.count': 'Αριθμός εταιρειών που πρόκειται να λήξουν',
  'company.register.expire.count': 'Ο αριθμός των εταιρειών που έχουν λήξει',
  'company.register.device.all.count': 'Τρέχων αριθμός εγγεγραμμένων συσκευών',
  'company.register.device.soon.count': 'Ο αριθμός των συσκευών που πρόκειται να λήξουν',
  'company.register.device.expire.count': 'Ο αριθμός των συσκευών που έχουν λήξει',
  'company.search': 'Αναζήτηση',
  'company.reset': 'Επαναφορά',
  'company.filter': 'Φίλτρο',
  'company.recharge.device.count': 'Αριθμός συσκευών επαναφόρτισης',
  'company.renewal.time': 'Χρόνος ανανέωσης',
  'company.renewal.duration': 'Χρόνος ανανέωσης',
  // 充值卡
  'company.card.day': 'Ημέρα',
  'company.card.month': 'Μήνας',
  'company.card.add.month': 'Μήνας',
  'company.card.year': 'Έτος',
  'company.card.stop': 'Απενεργοποίηση',
  'company.card.start': 'Ενεργοποίηση',
  'company.card.delete.fail':
    'Αυτή η κάρτα επαναφόρτισης έχει χρησιμοποιηθεί και δεν μπορεί να διαγραφεί!',
  'company.card.delete.tips':
    'Είστε βέβαιοι ότι θέλετε να διαγράψετε τις επιλεγμένες πληροφορίες προπληρωμένης κάρτας？',
  'company.card.add.fail': 'Αποτυχία προσθήκης προπληρωμένης κάρτας',
  'company.card.info.setting': 'Ρυθμίσεις πληροφοριών προπληρωμένης κάρτας',
  'company.card.pls.buy.count': 'Εισάγετε την ποσότητα αγοράς',
  'company.card.pls.term': 'Εισάγετε την ημερομηνία λήξης',
  'company.card.term.unit': 'Ενότητα όρου ',
  'company.card.device.model.not':
    'Το μοντέλο της εταιρικής συσκευής είναι κενό και οι πληροφορίες της κάρτας επαναφόρτισης δεν μπορούν να προστεθούν！',
  // 统计
  'company.device.type.statistics': 'Στατιστικά τύπου συσκευής',
  'company.sales.man.statistics': 'Στατιστικά Πωλήσεων',
  'company.sales.device.statistics': 'Προστέθηκαν στατιστικά στοιχεία συσκευής',
  'company.sales.device.count.statistics': 'Μηνιαία Στατιστικά Ποσοτήτων Εξοπλισμού Πωλήσεων',
  'company.sales.device.count': 'Ποσότητα εξοπλισμού πώλησης',
  'company.new.customers.statistics': 'Προσθήκη στατιστικών στοιχείων πελατών',
  'company.customers.register.count': 'Αριθμός εγγραφών πελατών',
  'company.new.customers.info.statistics': 'Προσθήκη στατιστικών στοιχείων πελατών',
  'company.system.access.statistics': 'Αρχείο καταγραφής πρόσβασης συστήματος',
  'company.sales.man.info.statistics': 'Στατιστικά προσωπικού πωλήσεων',
  'company.date': 'Ημερομηνία',
  'company.login.count': 'Συνδέσεις',
  'company.one.month.login.count':
    'Στατιστικά στοιχεία σχετικά με τη συχνότητα των συνδέσεων του κωδικού συστήματος cloud τον περασμένο μήνα',
  // 新增企业
  'company.add.set': 'Ρυθμίσεις Εταιρίας',
  'company.add.basics': 'Βασικές Πληροφορίες',
  'company.add.module.share': 'Δεν είναι δυνατή η κοινή χρήση του κινητού με άλλη συσκευή',
  'company.add.country.code.fail': 'Εισάγατε λανθασμένο κωδικό χώρας. Προσπαθήστε πάλι！',
  'company.add.pls.name': 'Εισάγετε επωνυμία εταιρίας',
  'company.add.pls.code': 'Εισάγετε κωδικό εταιρίας',
  'company.add.pls.model': 'Μοντέλο συσκευής εισόδου',
  'company.add.wrongful.code': 'Λάθος κωδικός εταιρίας',
  'company.add.code.length':
    'Το μήκος του κωδικού εταιρείας δεν μπορεί να είναι μικρότερο από τέσσερα ψηφία',
  'company.add.contact': 'Επαφή',
  'company.add.pls.contact': 'Εισάγετε επαφή',
  'company.add.contact.tel': 'Τηλέφωνο',
  'company.add.pls.contact.tel': 'Εισάγετε τηλέφωνο',
  'company.add.email': 'Email',
  'company.add.pls.email': 'Εισάγετε Email',
  'company.add.bengin.time': 'Ώρα Έναρξης',
  'company.add.end.time': 'Ώρα Λήξης',
  'company.add.province': 'Επαρχία',
  'company.add.city': 'Πόλη',
  'company.add.pls.city': 'Εισάγετε χώρα',
  'company.add.register.time': 'Χρόνος Εγγραφής',
  'company.add.sale.device.model': 'Μοντέλο Συσκευής',
  'company.add.configure': 'Πληροφορίες Ρυθμίσεων',
  'company.add.read.type': 'Λειτουργία μεταφόρτωσης δεδομένων',
  'company.add.omit.push.func': 'Λειτουργία Push',
  'company.add.create.user': 'Δημιουργία προσωπικού',
  'company.add.time.zone': 'Ζώνη ώρας',
  'company.add.time.zone.des': 'Παρακαλείστε να τηρείτε την τοπική ζώνη ώρας σας',
  'company.add.country.code': 'Κωδικός Χώρας',
  'company.add.country.app': 'Μέγιστοι χρήστες κινητού',
  'company.add.pls.country.code': 'Εισάγετε κωδικό εταιρίας',
  'company.add.show.device.model': 'Εμφάνιση μοντέλου συσκευής',
  'company.add.no.show.device.model': 'Να μην εμφανίζεται μοντέλο συσκευής',
  'company.add.data.package': 'Packet Mode',
  'company.add.base64.mode': 'Base64 Mode',
  'company.add.no.app.push.func': 'Χωρίς λειτουργία app push',
  'company.add.app.push.func': 'Με λειτουργία app push',
  'company.add.charge.or.not': 'Φόρτιση ή όχι',
  'company.add.no.charge': 'όχι φόρτιση',
  'company.add.charge': 'Φόρτιση',
  'company.add.companycode.info':
    'Δεν είναι δυνατή η χρήση Επισκέπτη ή Διαχειριστή ως εταιρικού κωδικού. Εισαγάγετε πάλι!',
  // 基础数据信息
  'company.basics.info': 'Βασικά Δεδομένα',
  'company.basics.dept.name': 'Όνομα Τμήματος',
  'company.basics.site.list': 'Λίστα σημείων ελέγχου',
  'company.basics.site.name': 'Όνομα Σημείου ελέγχου',
  'company.basics.site.code': 'ID σημείου ελέγχου',
  'company.basics.site.delete.tips': 'θέλετε να διαγράψετε το αρχείο;?',
  'company.basics.user.list': 'Λίστα Φυλάκων',
  'company.basics.user.name': 'Όνομα Φύλακα',
  'company.basics.user.code': 'ID Φύλακα',
  'company.basics.user.delete.tips': 'Θέλετε να διαγράψετε το αρχείο;',
  'company.basics.event.list': 'Λίστα Συμβάντων',
  'company.basics.event.name': 'Όνομα Συμβάντων',
  'company.basics.event.code': 'ID Συμβάντων',
  'company.basics.user.event.tips': 'θέλετε να διαγράψετε το αρχείο;',
  // 设备
  'company.device.change.end.time.success': 'Επιτυχής επεξεργασία',
  'company.device.number': 'Κωδικός συσκευής',
  'company.device.end.time': 'Ώρα λήξης',
  'company.device.belonging.enterprise': 'Εταιρία',
  'company.device.delete.info.tips': 'Θέλετε να διαγράψετε το αρχείο;',
  'company.device.change.end.time.tips':
    'Θέλετε να αλλάξετε την ώρα λήξης στην καταχωρημένη συσκευή;',
  'company.device.register.count': 'Αριθμός καταχωρημένων συσκευών',
  'company.device.register.info.statistics': 'Στατιστικά καταχωρημένων συσκευών',
};
