import request from '@/utils/request';
import { stringify } from 'qs';
import { getRequestPrefix } from '@/utils/profile';

export async function getPlans(params) {
  return request(`/${getRequestPrefix()}/map/plans?${stringify(params)}`);
}

export async function getMockDevices() {
  return request('/mock/map/devices');
}

export async function getCheckpoints(params) {
  return request(`/${getRequestPrefix()}/map/checkpoints?${stringify(params)}`);
}

export async function getTracks(params) {
  return request(`/${getRequestPrefix()}/map/tracks?${stringify(params)}`);
}

export async function closeAlarm(params) {
  return request(`/api/closeAlarm?ID=${params.alarmId}`);
}

export async function getTracksNew(params) {
  return request(`/${getRequestPrefix()}/map/tracksNew?${stringify(params)}`);
}