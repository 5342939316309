export default {
  'book.title': 'Liaison',
  'book.btn.add': 'Ajouter un contact',
  'book.btn.import': 'Importer des contacts',
  'book.btn.export': 'Export contact',
  'book.table.name': 'Nom du contact',
  'book.table.tel': 'Numéro de contact',
  'book.input.name': 'Veuillez saisir le nom du contact.',
  'book.input.tel': 'Veuillez entrer votre numéro de téléphone.',
  'book.table.moveup': 'Vers le haut',
  'book.table.movedown': 'Déplacer vers le bas',
};
