import {
  getDataReport,
  getDetailDataReport,
  getContentDetail,
  getContenItemDetail,
} from '@/services/plandata';

const MapModel = {
  namespace: 'plandata',
  state: {
    list: [],
  },
  effects: {
    *fetchPlanDatas({ payload, callback }, { call }) {
      try {
        const response = yield call(getDataReport, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *fetchPlanDetailDatas({ payload, callback }, { call }) {
      try {
        const response = yield call(getDetailDataReport, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *fetchContentDetail({ payload, callback }, { call }) {
      try {
        const response = yield call(getContentDetail, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *fetchContenItemDetail({ payload, callback }, { call }) {
      try {
        const response = yield call(getContenItemDetail, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
  },
  reducers: {},
};
export default MapModel;
