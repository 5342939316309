export default {
  'alarm.notification.title': '警報提醒',
  'alarm.notification.desc': '收到 {count} 條警報資料，請注意處理...',

  'alarm.notification.view': '檢視詳情',
  'alarm.notification.ignore': '忽略警報',

  'alarm.notification.list': '待處理警報',
  'alarm.notification.readAll': '全部處理',
  'alarm.notification.readAll.confirm': '是否處理全部警報？',

  'alarm.notification.read.title': '提示',
  'alarm.notification.read.done': '警報已標記為處理',
  'alarm.notification.read.error': '處理警報時發生錯誤',

  'alarm.manual': '手動警報',
  'alarm.low': '低電警報',
  'alarm.omit': '漏檢警報',
  'alarm.tilt': '傾斜警報',
  'alarm.motionless': '靜止警報',
  'alarm.collision': '摔碰警報',
  'alarm.dismantle': '開殼警報',
  'alarm.out': '出圈警報',
  'alarm.in': '進圈警報',

  'alarm.choice.no': '無警報功能',
  'alarm.choice.web': '僅網頁端警報',
  'alarm.choice.app': '僅手持端警報',
  'alarm.choice.all': '網頁及手持端均警報',
  'alarm.notification.title.desc': '是否在網頁端或手持端包含警報資料提醒功能',
};
