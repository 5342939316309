export default {
  'guard.title': 'Configuration de la garde',
  'guard.btn.batch': 'Lecture par lots',
  'guard.btn.add': 'Ajouter un garde',
  'guard.btn.edit': 'Modifier la garde',
  'guard.btn.del': 'Supprimer la garde',
  'guard.btn.import': "Garde d'importation",
  'guard.btn.export': "Garde d'exportation",
  'guard.table.code': 'ID de garde',
  'guard.table.name': 'Nom du garde',
  'guard.table.operate': 'Fonctionner',
  'guard.table.edit': 'Modifier',
  'guard.app.device': 'Liste des périphériques',
  'guard.app.placeholder': "Veuillez sélectionner l'appareil",
  'guard.finger.name': 'Empreinte digitale',
  'guard.finger.coll': 'Recueillir des empreintes digitales',
  'guard.finger.coll.success': "Recueillir l'empreinte digitale avec succès",
  'guard.finger.coll.failed': "Échec de la collecte d'empreintes digitales",
  'guard.finger.download': "Télécharger l'empreinte digitale",
  'guard.finger.download.success': "Téléchargement d'empreintes digitales réussi",
  'guard.finger.download.failed': 'Le téléchargement des empreintes digitales a échoué',
  'guard.finger.download.deldata':
    "Échec du téléchargement de l'empreinte digitale, avant de télécharger l'empreinte digitale, veuillez supprimer les données de l'appareil.",
  'guard.finger.download.nofinger':
    "Il n'y a pas d'informations d'empreintes digitales à télécharger.",
  'guard.input.guard.placeholder': 'Veuillez saisir le nom du gardien',
  'guard.finger.onlyOneLine': 'Une seule ligne peut être éditée en même temps',
  'guard.table.moveup': 'Vers le haut',
  'guard.table.movedown': 'Déplacer vers le bas',
};
