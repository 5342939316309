export default {
  'module.1': 'Configuration de la patrouille', 
  'module.101': "Configuration de l'entreprise", 
  'module.10101': 'Ajouter une entreprise', 
  'module.10102': "Modifier l'entreprise", 
  'module.10103': "Supprimer l'entreprise", 
  'module.10104': "Voir l'entreprise", 
  'module.102': "Configuration de l'événement", 
  'module.10201': 'Ajouter un évènement', 
  'module.10202': "Modifier l'événement", 
  'module.10203': "Supprimer l'événement", 
  'module.10204': 'Voir le menu', 
  'module.10205': 'Importer un événement', 
  'module.10206': "Exporter l'événement", 
  'module.10207': 'Lecture par lots', 
  'module.103': 'Configuration de la garde', 
  'module.10301': 'Ajouter un garde', 
  'module.10302': 'Modifier la garde', 
  'module.10303': 'Supprimer la garde', 
  'module.10304': 'Voir le menu', 
  'module.10305': "Garde d'importation", 
  'module.10306': "Garde d'exportation", 
  'module.10307': 'Lecture par lots', 
  'module.10308': 'Recueillir des empreintes digitales', 
  'module.10309': 'Editer votre empreinte', 
  'module.10310': "Télécharger l'empreinte digitale",
  'module.104': 'Configuration du point de contrôle', 
  'module.10401': 'Ajouter un point de contrôle', 
  'module.10402': 'Modifier', 
  'module.10403': 'Supprimer le point de contrôle', 
  'module.10404': 'Voir le menu', 
  'module.10405': "Point de contrôle d'importation",
  'module.10406': 'Télécharger les données',
  'module.10407': 'Lecture par lots', 
  'module.10408': 'Attribuer du contenu', 
  'module.10409': "Point de contrôle d'exportation",
  'module.105': 'Configuration du forfait', 
  'module.10501': 'Ajouter un forfait', 
  'module.10502': 'Modifier le calendrier', 
  'module.10503': 'Supprimer le calendrier', 
  'module.10504': 'Voir le menu', 
  'module.10505': 'Attribuer un appareil', 
  'module.10506': "Plan d'exportation",
  'module.106': 'Enregistrement de l’équipement d’inspection', 
  'module.10601': 'Enregistrement des équipements', 
  'module.10602': 'Supprimer un appareil', 
  'module.10604': 'Voir le menu', 
  'module.107': 'Configuration des contacts', 
  'module.10701': 'Ajouter un contact', 
  'module.10702': 'Modifier', 
  'module.10703': 'Supprimer', 
  'module.10704': 'Voir le menu', 
  'module.10706': 'Importer des contacts', 
  'module.10705': 'Export contact', 
  'module.108': 'Informations sur le reçu', 
  'module.10801': 'Ajouter un reçu', 
  'module.10802': 'Modifier le message de retour', 
  'module.10803': 'Supprimer le message de retour', 
  'module.10804': 'Voir le menu', 
  'module.10805': "Reçu d'importation",
  'module.10806': "Reçu d'exportation",
  'module.109': 'Télécharger les données', 
  'module.10901': 'Télécharger les données', 
  'module.10904': 'Voir le menu', 
  'module.110': 'Configuration du contenu', 
  'module.11001': 'Ajouter du contenu', 
  'module.11002': 'Modifier le contenu', 
  'module.11003': 'Supprimer le contenu', 
  'module.11004': 'Voir le menu', 
  'module.11005': 'Sélectionnez le point de contrôle', 
  'module.111': 'Configuration du talkie-walkie', 
  'module.11101': 'Ajouter un groupe', 
  'module.11102': 'Modifier le groupe', 
  'module.11103': 'Supprimer le groupe', 
  'module.11104': 'Voir le menu', 
  'module.112': 'Configuration vocale', 
  'module.11204': 'Voir le menu', 
  'module.113': 'Télécharger les données', 
  'module.11301': 'Télécharger les données', 
  'module.11304': 'Voir le menu', 
  'module.114': 'La clôture électronique', 
  'module.11401': 'Nouvelles clôtures ajoutées', 
  'module.11402': 'Éditer la clôture', 
  'module.11403': 'Supprimer la clôture', 
  'module.11404': 'Voir le menu', 
  'module.2': 'Opération de base', 
  'module.201': 'Lire les données', 
  'module.20104': 'Lire les données', 
  'module.202': "Données d'historique",
  'module.20201': 'Exporter des données historiques', 
  'module.20203': 'Réanalyse', 
  'module.20204': 'Voir le menu', 
  'module.203': 'Rapport de patrouille', 
  'module.20301': 'Exporter le rapport d’inspection', 
  'module.20304': 'Voir le menu', 
  'module.204': 'Tableau statistique', 
  'module.20401': 'Exporter vos statistiques', 
  'module.20404': 'Voir le menu', 
  'module.205': "Données d'omission",
  'module.20501': 'Exportation des données de détection manquantes', 
  'module.20504': 'Voir le menu', 
  'module.206':  "Données d'alarme",
  'module.20601': 'Exporter les données d’alarme', 
  'module.20603': 'Supprimer les données d’alarme', 
  'module.20604': 'Voir le menu', 
  'module.207': 'Informations sur le journal', 
  'module.20701': 'Exporter les données de log', 
  'module.20704': 'Voir le menu', 
  'module.208': 'Effacer les données du périphérique', 
  'module.20804': 'Effacer les données du périphérique', 
  'module.209': "Synchronisation de l'appareil",
  'module.20904': "Synchronisation de l'appareil",
  'module.210': 'Empreintes claires', 
  'module.21004': 'Empreintes claires', 
  'module.211': 'Surveillance', 
  'module.21101': 'Modifier un spot', 
  'module.21102': 'Lecture de la piste', 
  'module.21104': 'Voir le menu', 
  'module.21105': 'Voir les données en temps réel', 
  'module.212': "Données d'origine",
  'module.21201': 'Exporter les données brutes', 
  'module.21202': 'Conversion de carte', 
  'module.21204': 'Voir le menu', 
  'module.213': 'Calendrier des patrouilles', 
  'module.21304': 'Voir le menu', 
  'module.214': 'Données de fréquentation', 
  'module.21401': 'Exporter les données de présence', 
  'module.21404': 'Voir le menu', 
  'module.215': 'Report Detail', 
  'module.21501': 'Exporter les détails d’inspection', 
  'module.21504': 'Voir le menu', 
  'module.3': 'Maintenance des données', 
  'module.302': 'Liste des périphériques', 
  'module.30201':  "Activer l'appareil",
  'module.30202': 'Ajouter un appareil', 
  'module.30203': 'Supprimer un appareil', 
  'module.30204': 'Voir le menu', 
  'module.30205': "Remplacer l'appareil",
  'module.30206': 'Configuration des paramètres', 
  'module.30207': 'Modifier la remarque', 
  'module.303': 'Configuration des paramètres système', 
  'module.30304': 'Configuration des paramètres système', 
  'module.304': "Configuration des paramètres de l'appareil",
  'module.30404': 'Voir les paramètres de l’appareil', 
  'module.30408': "Réglage de l'horloge",
  'module.305': 'Paramètres de communication. Définir les paramètres', 
  'module.30504': 'Voir les paramètres de communication', 
  'module.306': 'Configuration des autorisations', 
  'module.30601': 'Ajouter un rôle', 
  'module.30602': "Modifier le rôle", 
  'module.30603': "Supprimer le rôle", 
  'module.30604': 'Voir le menu', 
  'module.307': "Configuration de l'opérateur",
  'module.30701': 'Ajouter un opérateur', 
  'module.30702': "Modifier l'opérateur", 
  'module.30703': "Supprimer l'opérateur", 
  'module.30704': 'Voir le menu', 
  'module.30705': "Voir l'entreprise",
  'module.30706': 'Attribuer une autorisation', 
  'module.30708': 'Modifier le mot de passe', 
  'module.308': 'Initialisation du périphérique', 
  'module.30804': 'Initialisation du périphérique', 
  'module.5': 'Tableau de bord', 
  'module.216': 'BI',
  'module.21604': 'Voir le menu', 
};
