export default {
  'module.1': '巡檢設置',
  'module.101': '區域設置',
  'module.10101': '新增區域',
  'module.10102': '編輯區域',
  'module.10103': '刪除區域',
  'module.10104': '查看區域',
  'module.102': '事件設置',
  'module.10201': '增加事件',
  'module.10202': '編輯事件',
  'module.10203': '刪除事件',
  'module.10204': '查看菜單',
  'module.10205': '導入事件',
  'module.10206': '導出事件',
  'module.10207': '批量讀取',
  'module.103': '人員設置',
  'module.10301': '增加人員',
  'module.10302': '編輯人員',
  'module.10303': '刪除人員',
  'module.10304': '查看菜單',
  'module.10305': '導入人員',
  'module.10306': '導出人員',
  'module.10307': '批量讀取',
  'module.10308': '採集指紋',
  'module.10309': '編輯指紋',
  'module.10310': '下載指紋',
  'module.104': '地點設置',
  'module.10401': '增加地點',
  'module.10402': '編輯地點',
  'module.10403': '刪除地點',
  'module.10404': '查看菜單',
  'module.10405': '導入地點',
  'module.10406': '下載數據',
  'module.10407': '批量讀取',
  'module.10408': '分配內容',
  'module.10409': '導出地點',
  'module.105': '計劃設置',
  'module.10501': '增加計劃',
  'module.10502': '編輯巡檢計劃',
  'module.10503': '刪除巡檢計劃',
  'module.10504': '查看菜單',
  'module.10505': '綁定設備',
  'module.10506': '導出計劃',
  'module.106': '巡檢設備註冊',
  'module.10601': '設備註冊',
  'module.10602': '刪除設備',
  'module.10604': '查看菜單',
  'module.107': '通訊錄設置',
  'module.10701': '增加通訊錄',
  'module.10702': '編輯通訊錄',
  'module.10703': '刪除通訊錄',
  'module.10704': '查看菜單',
  'module.10706': '導入通訊錄',
  'module.10705': '導出通訊錄',
  'module.108': '回執信息',
  'module.10801': '增加回執信息',
  'module.10802': '編輯回執信息',
  'module.10803': '刪除回執信息',
  'module.10804': '查看菜單',
  'module.10805': '導入回執信息',
  'module.10806': '導出回執信息',
  'module.109': '下載數據',
  'module.10901': '下載數據',
  'module.10904': '查看菜單',
  'module.110': '巡檢內容',
  'module.11001': '增加內容',
  'module.11002': '編輯內容',
  'module.11003': '刪除內容',
  'module.11004': '查看菜單',
  'module.11005': '選擇地點',
  'module.111': '對講設置',
  'module.11101': '新增組',
  'module.11102': '編輯組',
  'module.11103': '刪除組',
  'module.11104': '查看菜單',
  'module.112': '巡檢語音',
  'module.11204': '查看菜單',
  'module.113': '下載數據',
  'module.11301': '下載數據',
  'module.11304': '查看菜單',
  'module.114': '電子圍欄',
  'module.11401': '新增圍欄',
  'module.11402': '編輯圍欄',
  'module.11403': '刪除圍欄',
  'module.11404': '查看菜單',
  'module.2': '基礎操作',
  'module.201': '讀取數據',
  'module.20104': '讀取數據',
  'module.202': '曆史數據',
  'module.20201': '導出歷史數據',
  'module.20203': '重新分析',
  'module.20204': '查看菜單',
  'module.203': '巡檢報告',
  'module.20301': '導出巡檢報告',
  'module.20304': '查看菜單',
  'module.204': '統計圖表',
  'module.20401': '導出統計數據',
  'module.20404': '查看菜單',
  'module.205': '漏檢數據',
  'module.20501': '導出漏檢數據',
  'module.20504': '查看菜單',
  'module.206': '報警數據',
  'module.20601': '導出報警數據',
  'module.20603': '刪除報警數據',
  'module.20604': '查看菜單',
  'module.207': '日志數據',
  'module.20701': '導出日誌數據',
  'module.20704': '查看菜單',
  'module.208': '清除設備數據',
  'module.20804': '清除設備數據',
  'module.209': '設備校時',
  'module.20904': '設備校時',
  'module.210': '清除設備指紋',
  'module.21004': '清除設備指紋',
  'module.211': '實時監控',
  'module.21101': '編輯點位',
  'module.21102': '軌跡回放',
  'module.21104': '查看菜單',
  'module.21105': '查看實時數據',
  'module.212': '原始數據',
  'module.21201': '導出原始數據',
  'module.21202': '未知卡轉換',
  'module.21204': '查看菜單',
  'module.213': '日曆展示',
  'module.21304': '查看菜單',
  'module.214': '考勤數據',
  'module.21401': '導出考勤數據',
  'module.21404': '查看菜單',
  'module.215': '巡檢明細',
  'module.21501': '導出巡檢明細',
  'module.21504': '查看菜單',
  'module.3': '數據維護',
  'module.302': '巡檢設備列表',
  'module.30201': '激活設備',
  'module.30202': '添加設備',
  'module.30203': '刪除設備',
  'module.30204': '查看菜單',
  'module.30205': '設備替換',
  'module.30206': '參數設置',
  'module.30207': '修改備註',
  'module.303': '系統參數設置',
  'module.30304': '系統參數設置',
  'module.304': '設備參數設置',
  'module.30404': '查看設備參數設置',
  'module.30408': '鬧鈴設定',
  'module.305': '通訊參數設定',
  'module.30504': '查看通訊參數設置',
  'module.306': '系統權限設置',
  'module.30601': '增加角色',
  'module.30602': '編輯角色信息',
  'module.30603': '刪除角色信息',
  'module.30604': '查看菜單',
  'module.307': '操作員管理',
  'module.30701': '增加操作員',
  'module.30702': '編輯操作員',
  'module.30703': '刪除操作員',
  'module.30704': '查看菜單',
  'module.30705': '查看區域',
  'module.30706': '權限分配',
  'module.30708': '修改密碼',
  'module.308': '設備初始化',
  'module.30804': '設備初始化',
  'module.5': '工作台',
  'module.216': 'BI可視化大屏',
  'module.21604': '查看菜單',
};
