export default {
  'common.table.batchdel': '批量删除',
  'common.table.del': '删除',
  'common.table.moveup': '上移记录',
  'common.table.movedown': '下移记录',
  'common.table.cancel': '取消选择',
  'common.table.selected': '已选',
  'common.table.item': '项',
  'common.btn.success': '操作成功',
  'common.btn.download.success': '数据下载成功',
  'common.btn.download.failed': '数据下载失败',
  'common.btn.confirm': '确定',
  'common.btn.cancel': '取消',
  'common.btn.close': '关闭',
  'common.btn.template': '下载模板',
  'common.btn.import.label': '请选择导入文件',
  'common.btn.export.excel': '导出Excel格式',
  'common.btn.export.pdf': '导出Pdf格式',
  'common.btn.import.title': '单击上传',
  'common.btn.home': '返回首页',
  'common.message.prompt': '提示',
  'common.placeholder.message': '请输入',
  'common.device.nodata': '设备内无数据，请读卡后重新上传',
  'common.notification.description.batchsuccess': '批量读取成功，读取的记录数为：',
  'common.notification.description.records': '本次录入的记录数为：',
  'common.notification.description.nodata': '没有满足条件的数据，请重新选择查询条件',
  'common.notification.description.required': '此项为必填项',
  'common.notification.query.range': '查询时间范围不能超过60天，请重新选择查询条件',
  'common.websocket.version': '检测到您的通讯组件版本过低，为了不影响正常使用请下载新版通讯组件！（先卸载再安装）',
};
