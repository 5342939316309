export default {
  'sound.title': 'Голосовая информация патруля',
  'sound.no': 'Серийный номер',
  'sound.name': 'Звуковой контент',
  'sound.name.placeholder': 'Пожалуйста, введите голосовое содержание',
  'sound.dept.placeholder': 'Пожалуйста, выберите область',
  'sound.table.operate': 'Операция',
  'sound.table.edit': 'Редактировать',
  'sound.table.add': 'Добавить голос',
  'sound.table.home': 'Вернуться на домашнюю страницу',
  'sound.table.voice': 'Создать голосовую библиотеку',
  'sound.table.clear': 'Очистить голос устройства',
  'sound.table.voice.success': 'Голосовая библиотека успешно сгенерирована! ',
  'sound.table.voice.err': 'Не удалось создать голосовую библиотеку! ',
  'sound.table.delete': 'удалить',
  'sound.table.selected': 'выбрано',
  'sound.table.item': 'элемент',
  'sound.modal.delconfirm': 'Вы уверены, что хотите удалить выбранные данные? ',
  'sound.table.deletesuccess': 'Данные успешно удалены',
  'sound.modal.ok': 'ОК',
  'sound.modal.cancel': 'Отмена',
  'sound.modal.error': 'Операция не удалась',
  'sound.message.loading.add': 'Добавление...',
  'sound.message.loading.edit': 'Настройка...',
  'sound.genvoice.loading': 'Голосовой файл создается, пожалуйста, подождите...',
  'sound.genvoice.invalidDevice': 'Текущее устройство доступа не поддерживает голосовую функцию! ',
};
