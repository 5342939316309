import menu from './zh-TW/menu';
import pwa from './zh-TW/pwa';
import exception from './zh-TW/exception';
import request from './zh-TW/request';
import login from './zh-TW/login';
import dashboard from './zh-TW/dashboard';
import header from './zh-TW/header';
import general from './zh-TW/general';
import map from './zh-TW/map';
import device from './zh-TW/device';
import system from './zh-TW/system';
import sound from './zh-TW/sound';
import communication from './zh-TW/communication';
import alarm from './zh-TW/alarm';
import dept from './zh-TW/dept';
import event from './zh-TW/event';
import guard from './zh-TW/guard';
import site from './zh-TW/site';
import plan from './zh-TW/plan';
import role from './zh-TW/role';
import user from './zh-TW/user';
import talk from './zh-TW/talk';
import content from './zh-TW/content';
import book from './zh-TW/book';
import receipt from './zh-TW/receipt';
import query from './zh-TW/query';
import common from './zh-TW/common';
import bi from './zh-TW/bi';
import company from './zh-TW/company';
import enclosure from './zh-TW/enclosure';
import module from './zh-TW/module';
import backup from './zh-TW/backup';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,
  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...plan,
  ...content,
  ...common,
  ...user,
  ...role,
  ...book,
  ...receipt,
  ...query,
  ...talk,
  ...enclosure,
  ...backup,

  ...bi,
  ...company,

  ...module,

  app: '碼雲巡檢平臺',
  appMobile: '巡檢平臺系統-巡檢寶',
  producer: '沈陽金萬碼高科技發展有限公司',
  oldaddress: '老版本地址',
  sys_notification:
    '由於雲平臺底層硬件服務升級可能對系統造成的影響，本系統將於4月3日淩晨進行維護升級，升級時間預計1小時，系統升級期間，數據將無法上傳',
};
