export default {
  'bi.title': 'Patrol Visualized Platform',
  'bi.guard': 'Guard',
  'bi.site': 'Site',
  'bi.source': 'Source',
  'bi.plan': 'Plan',
  'bi.device': 'Device',
  'bi.record': 'Record',

  'bi.time': 'Time',
  'bi.no': 'No.',

  'bi.record.trend': 'Data Traffic Trend',
  'bi.record.average': 'Average',
  'bi.record.upload': 'Upload Traffic',
  'bi.record.unit': ' ',

  'bi.record.list': 'Data Traffic List',

  'bi.qualified': 'Qualified',
  'bi.unqualified': 'Omit',
  'bi.todo': 'To-do',

  'bi.qualified.rate': 'Qualified Rate',
  'bi.unqualified.rate': 'Omit Rate',

  'bi.rate.today': 'Today Statistics',
  'bi.attendacne.rank': 'Attendance Rank',
  'bi.rate.trend': 'Statistics Chart',

  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  'bi.empty': 'No data here',

  'bi.largest.guide':
    'Please adjust window to maximize size to get a better experience of the BI screen',
  'bi.largest.ignore': 'Ignore',
};
