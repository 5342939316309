export default {
  'enclosure.title': 'Electronic fence',
  'enclosure.table.name': 'Fence name',
  'enclosure.table.name.rule': 'Please enter the fence name',
  'enclosure.table.timeRange': 'Time frame',
  'enclosure.input.timeRange.add': 'Add time',
  'enclosure.btn.add': 'Add fencing',
  'enclosure.warn.updateAndNotSave':
    'If the Settings are not saved after the fence is modified, is it still closed?',
  'enclosure.btn.clear': 'Clear the fence',
  'enclosure.clear.confirm': 'Sure you want to clear the fence data?',
  'enclosure.btn.del': 'Remove fence',
  'enclosure.del.confirm': 'Are you sure you want to delete fence data?',
  'enclosure.btn.currentSite': 'Current position',
  'enclosure.table.btn.fence': 'fence',
  'enclosure.warn.timeRange.coincide': 'Time range overlaps, please reset',
  'enclosure.warn.timeRange.empty': 'Please select time',
  'enclosure.warn.timeRange.notCheck': 'The time range cannot be empty',
};
