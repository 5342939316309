import {
  getChartBar,
  getChartRank,
  getLatestAlarms,
  getLatestHistories,
  getStatisticOverview,
  getSystemOverview,
} from '@/services/dashboard';
import moment from 'moment';

export default {
  // 命名空间 (必填)
  namespace: 'bi',

  // 数据
  state: {
    query: {
      startDate: moment().add(-6, 'day').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    },
    history: {
      trend: [],
      recent: [],
    },
    statistic: {
      total: 0,
      qualified: 0,
      unqualified: 0,
      todo: 0,
    },
    rank: [],
    chart: [],
    system: {
      plan: {
        total: 0, // 总数
        expired: 0, // 过期数量
      },
      site: {
        total: 0,
        use: 0, // 使用中的数量（分配到计划中）
        // 如果是巡检宝
        qr: 0,
        gps: 0,
        nfc: 0,
        ble: 0,
      },
      guard: {
        total: 0,
      },
      device: {
        total: 0,
        use: 0, // 正常使用中数量
        expired: 0, // 过期数量
        inactive: 0, // 未激活数量
      },
    },
    alarm: [],
  },

  // 路由监听
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen((location) => {
        // 参数可以直接简写成{pathname}
        if (location.pathname === '/bi') {
          dispatch({ type: 'getHistories' });
          dispatch({ type: 'getStatistic' });
          dispatch({ type: 'getRank' });
          dispatch({ type: 'getChart' });
          dispatch({ type: 'getSystem' });
          dispatch({ type: 'getAlarm' });
        }
      });
    },
  },

  // 异步请求
  effects: {
    *getHistories(_, { call, put }) {
      const result = yield call(getLatestHistories, {
        // TODO: 这个时间范围居然是默认写死的10天？？
        pageIndex: 1,
        pageSize: 20,
      });
      // console.log('rrr', result);
      if (result) {
        yield put({
          type: 'setHistories',
          payload: result,
        });
      }
    },
    *getStatistic(_, { call, put }) {
      const result = yield call(getStatisticOverview);
      // console.log('sss', result);
      if (result) {
        yield put({
          type: 'setStatistic',
          payload: result,
        });
      }
    },
    *getRank(_, { call, put, select }) {
      const { startDate, endDate } = yield select((state) => state.bi.query);
      const result = yield call(getChartRank, {
        // TODO:: 排行榜查询范围默认应当是今日还是本周？？？
        startDate,
        endDate,
        rankType: 'realGuardName',
      });
      // console.log('kkk', result);
      if (result) {
        yield put({
          type: 'setRank',
          payload: result,
        });
      }
    },
    *getChart(_, { call, put, select }) {
      const { startDate, endDate } = yield select((state) => state.bi.query);
      const result = yield call(getChartBar, {
        startDate,
        endDate,
        deptItems: '',
      });
      // console.log('bbb', result);
      if (result) {
        yield put({
          type: 'setChart',
          payload: result,
        });
      }
    },
    *getSystem(_, { call, put }) {
      const result = yield call(getSystemOverview);
      // console.log('ooo', result);
      if (result) {
        yield put({
          type: 'setSystem',
          payload: result,
        });
      }
    },
    *getAlarm(_, { call, put }) {
      const result = yield call(getLatestAlarms, {
        pageIndex: 1,
        pageSize: 20,
      });
      // console.log('mmm', result);
      if (result) {
        yield put({
          type: 'setAlarm',
          payload: result,
        });
      }
    },
  },

  // 同步操作
  reducers: {
    setHistories(state, action) {
      return {
        ...state,
        history: {
          trend: action.payload.trend,
          recent: action.payload.list,
        },
      };
    },
    setStatistic(state, action) {
      return {
        ...state,
        statistic: action.payload,
      };
    },
    setRank(state, action) {
      return {
        ...state,
        rank: action.payload,
      };
    },
    setChart(state, action) {
      return {
        ...state,
        chart: action.payload,
      };
    },
    setSystem(state, action) {
      return {
        ...state,
        system: action.payload,
      };
    },
    setAlarm(state, action) {
      return {
        ...state,
        alarm: action.payload,
      };
    },
  },
};
