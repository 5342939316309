export default {
  'plan.title': 'Ρύθμιση Πλάνου',
  'plan.title.special': 'Ρύθμιση ειδικού πλάνου',
  'plan.btn.add': 'Προσθήκη Πλάνου',
  'plan.btn.addTemporary': 'Προσθήκη προσωρινού Πλάνου',
  'plan.btn.bind': 'Αντιστοίχηση συσκευής',
  'plan.btn.copy': 'Αντιγραφή Πλάνου',
  'plan.btn.paste': 'Επικόλληση Πλάνου',
  'plan.btn.export': 'Εξαγωγή Πλάνου',
  'plan.btn.batch': 'Μαζικό Ωράριο',
  'plan.btn.addshift': 'Προσθήκη Ωραρίου',
  'plan.btn.delshift': 'Διαγραφή Ωραρίου',
  'plan.btn.guard': 'Ρύθμιση Φυλάκων',
  'plan.btn.addrest': 'Προσθήκη Ανάπαυσης',
  'plan.btn.Manu': 'Χειροκίνητη αντιστοίχιση',
  'plan.modal.label.next': 'Την επόμενη ημέρα',
  'plan.modal.label.onetime': 'Χρόνος περιπολίας μίας φοράς (λεπτά)',
  'plan.modal.label.resttime': 'Χρόνος ανάπαυσης (λεπτά)',
  'plan.modal.label.no': 'Αρ ',
  'plan.modal.label.day': ' ημέρα',
  'plan.modal.label.weekrest': 'Εβδομαδιαία ανάπαυση',
  'plan.modal.label.specialrest': 'Ειδική ανάπαυση',
  'plan.modal.label.period': 'Περίοδος Περιπολίας',
  'plan.modal.label.unit': 'Μονάδα',
  'plan.modal.label.plantimes': 'Ώρες Πλάνου',
  'plan.modal.label.times': 'Φορές',
  'plan.modal.label.cost': 'Χρόνος για περιπολία ενός κύκλου',
  'plan.modal.label.interval': 'Διάστημα δύο κύκλων',
  'plan.modal.label.month': 'Μήνας',
  'plan.modal.label.week': 'Εβδομάδα',
  'plan.modal.label.hour': 'Ώρα',
  'plan.modal.label.minute': 'Λεπτό',
  'plan.modal.label.days': 'Ημέρα',
  'plan.table.schedule': 'Ωράριο',
  'plan.table.restday': 'Ανάπαυση',
  'plan.table.monday': 'Δευτέρα',
  'plan.table.tuesday': 'Τρίτη',
  'plan.table.wednesday': 'Τετάρτη',
  'plan.table.thursday': 'Πέμπτη',
  'plan.table.friday': 'Παρασκευή',
  'plan.table.saturday': 'Σάββατο',
  'plan.table.sunday': 'Κυριακή',
  'plan.table.type': 'Τύπος Πλάνου',
  'plan.table.per': 'Περ. ',
  'plan.table.month': ' μήνας',
  'plan.table.patrol': ' περιπολία',
  'plan.tab.plan': 'Πλάνο',
  'plan.tab.special': 'Ειδικό Πλάνο',
  'plan.tab.schedule': 'Ωράριο Περιπολίας',
  'plan.step.basic': 'Βασικές Ρυθμίσεις',
  'plan.step.site': 'Ρύθμιση Σημείων ελέγχου',
  'plan.step.shift': 'Ρύθμιση Ωραρίου',
  'plan.step.next': 'Επόμενο',
  'plan.step.prev': 'Προηγ',
  'plan.step.submit': 'Υποβολή',
  'plan.step.rest': 'Ρύθμιση ημέρας ανάπαυσης',
  'plan.transfer.site.select': 'Επιλεγμένα Σημεία Ελέγχου',
  'plan.transfer.site.unselect': 'Μη επιλεγμένα Σημεία Ελέγχου',
  'plan.input.plan.placeholder': 'Παρακαλώ εισάγετε ονομασία Πλάνου',
  'plan.notification.description.save.success': 'Επιτυχής αποθήκευση πλάνου',
  'plan.notification.description.sitefirst': 'Παρακαλώ ρυθμίστε σημείο ελέγχου πριν',
  'plan.notification.description.schedulefirst': 'Παρακαλώ ρυθμίστε ωράρια πρώτα',
  'plan.notification.description.exitplan':
    'Τα δεδομένα αυτής της ημέρας έχουν δημιουργηθεί σε αυτό το πρόγραμμα. Θέλετε να αντικατασταθούν τα δεδομένα και να αναδημιουργηθούν νέα δεδομένα ?',
  'plan.notification.description.than24': 'Κάθε χρόνος περιπολίας μπορεί να είναι εντός 24 ωρών.',
  'plan.notification.description.repeat': 'Επανάληψη χρόνου',
  'plan.notification.description.date':
    'Η ώρα λήξης πρέπει να είναι μεταγενέστερη από την ώρα έναρξης',
  'plan.notification.description.exitdate':
    'Η επιλεγμένη ημερομηνία υπάρχει ήδη, παρακαλώ επιλέξτε πάλι',
  'plan.notification.description.exitdevice': 'Η συσκευή υπάρχει ήδη. Παρτακαλώ προσθέστε την πάλι',
  'plan.notification.description.appuser': 'Πρέπει να ρυθμίσετε φύλακα',
  'plan.notification.description.copy.success': 'Επιτυχής αντιγραφή πλάνου',
  'plan.notification.description.copyfirst': 'Παρακαλώ αντιγράψτε το πλάνο πρώτα',
  'plan.notification.description.selfirst': 'Παρακαλώ επιλέξτε πλάνο',
  'plan.notification.description.selone': 'Επιλέξτε μια εγγραφή για λειτουργία',
  'plan.notification.description.patrolone':
    'Ο αριθμός των χρόνων περιπολίας ισχύει για μία φορά και το διάστημα πρέπει να μηδενιστεί',
  'plan.Modal.confirm.paste':
    'Είστε βέβαιοι ότι θέλετε να επικολλήσετε το αντιγραμμένο πλάνο στην τρέχουσα εταιρεία;',
  'plan.Modal.confirm.restday': 'Παρακαλώ επιλέξτε ημέρα ανάπαυσης πρώτα.',
  'plan.shift.day.wrong':
    'Το ημερήσιο πλάνο μπορεί να δημιουργηθεί μόνο για 31 ημέρες το πολύ. Εισαγάγετε πάλι την περίοδο περιπολίας!',
  'plan.shift.week.wrong':
    'Το εβδομαδιαίο πλάνο μπορεί να δημιουργηθεί μόνο για 4 εβδομάδες το πολύ. Εισαγάγετε ξανά την περίοδο περιπολίας!',
  'plan.shift.month.wrong':
    'Το μηνιαίο πλάνο μπορεί να δημιουργηθεί μόνο για 3 μήνες το πολύ. Εισαγάγετε ξανά την περίοδο περιπολίας!',
  'plan.rest.wrong':
    'Δεν είναι δυνατή η ρύθμιση όλων των ημερών σε ημέρες εβδομαδιαίας ανάπαυσης, Παρακαλώ κάνετε επαναφορά',
  'plan.bin.title': 'Δέσμευση υπάρχοντος σχεδίου',
  'plan.bin.wrong': 'Το χρονοδιάγραμμα είναι ήδη συνδεδεμένο με ένα άλλο προσωρινό χρονοδιάγραμμα και δεν μπορεί να το κάνει',
  'plan.bin.tooltip': 'Το δεσμευμένο σχέδιο θα σταματήσει να παράγει εκθέσεις επιθεώρησης μέχρι να λήξει το προσωρινό σχέδιο',
};
