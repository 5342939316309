// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import BulbOutlined from '@ant-design/icons/BulbOutlined'

export default {
  DashboardOutlined,
ControlOutlined,
DatabaseOutlined,
BulbOutlined
}
    