export default {
  'dashboard.charts': 'Диаграммы',
  'dashboard.charts.title.trend': 'Трендовая диаграмма',
  'dashboard.charts.title.rank': 'Графики',
  'dashboard.charts.dept.placeholder': 'Выберите регион',
  'dashboard.charts.rank.dept': 'регион',
  'dashboard.charts.rank.plan': 'План',
  'dashboard.charts.rank.guard': 'человек',
  'dashboard.charts.rank.site': 'Местоположение',
  'dashboard.charts.date.today': 'Сегодня',
  'dashboard.charts.date.week': 'Эта неделя',
  'dashboard.charts.date.month': 'в этом месяце',
  'dashboard.charts.orderby.asc': 'По возрастанию',
  'dashboard.charts.orderby.desc': 'По убыванию',
  'dashboard.statistics.type': 'тип',
  'dashboard.statistics.type.qualified': 'квалифицированный',
  'dashboard.statistics.type.unqualified': 'отсутствует патруль',
  'dashboard.statistics.type.todo': 'Должен быть посещен',
  'dashboard.statistics.columnes.count': 'Число',
  'dashboard.statistics.columnes.rate': 'Доля',
  'dashboard.statistics.title.percent': 'Сегодняшняя результативность',
  'dashboard.latestHistories.columnes.site': 'Местоположение',
  'dashboard.latestHistories.columnes.guard': 'человек/оборудование',
  'dashboard.latestHistories.columnes.info': 'События',
  'dashboard.latestHistories.columnes.remark': 'Примечания',
  'dashboard.latestHistories.columnes.time': 'время',
  'dashboard.latestHistories.columnes.extra': 'Медиа',
  'dashboard.latestHistories.title.trend': 'Тенденция загрузки последних данных',
  'dashboard.latestAlarms.title.alarmData': 'Недавние тревоги',
  'dashboard.latestLogs.columnes.action': 'Интерфейс и действие',
  'dashboard.latestLogs.columnes.time': 'время',
  'dashboard.latestLogs.columnes.reader': 'Устройство',
  'dashboard.latestLogs.columnes.user': 'Пользователь',
  'dashboard.latestLogs.title.log': 'Недавние журналы',
  'dashboard.card.extra.refresh': 'Обновить данные',
  'dashboard.card.extra.history': 'Перейти к интерфейсу исторических данных',
  'dashboard.card.extra.alarm': 'Перейти к интерфейсу данных тревоги',
  'dashboard.card.extra.log': 'Перейти к интерфейсу данных журнала',
};
