import menu from './ru-RU/menu';
import pwa from './ru-RU/pwa';
import exception from './ru-RU/exception';
import request from './ru-RU/request';
import login from './ru-RU/login';
import dashboard from './ru-RU/dashboard';
import header from './ru-RU/header';
import general from './ru-RU/general';
import map from './ru-RU/map';
import device from './ru-RU/device';
import system from './ru-RU/system';
import sound from './ru-RU/sound';
import communication from './ru-RU/communication';
import alarm from './ru-RU/alarm';
import dept from './ru-RU/dept';
import event from './ru-RU/event';
import guard from './ru-RU/guard';
import site from './ru-RU/site';
import plan from './ru-RU/plan';
import role from './ru-RU/role';
import user from './ru-RU/user';
import talk from './ru-RU/talk';
import content from './ru-RU/content';
import book from './ru-RU/book';
import receipt from './ru-RU/receipt';
import query from './ru-RU/query';
import common from './ru-RU/common';
import bi from './ru-RU/bi';
import company from './ru-RU/company';
import enclosure from './ru-RU/enclosure';
import module from './ru-RU/module';
import backup from './ru-RU/backup';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,
  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...plan,
  ...content,
  ...common,
  ...user,
  ...role,
  ...book,
  ...receipt,
  ...query,
  ...talk,
  ...enclosure,
  ...backup,

  ...bi,
  ...company,

  ...module,

  app: 'система обхода',
  appMobile: 'система обхода',
  producer: 'JWM Hi-Tech Development Ltd.,',
  oldaddress: 'адрес старой версии',
  sys_notification:
    'Поскольку обновление базового аппаратного обеспечения облачной платформы может повлиять на систему, обновление системы состоится рано утром 3 апреля по пекинскому времени. Ожидается, что время обновления составит один час. Во время обновления системы данные не загружаются.',
};
