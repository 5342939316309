export default {
  'bi.title': '巡检数据可视化大屏',
  'bi.guard': '人员',
  'bi.site': '地点',
  'bi.source': '来源',
  'bi.plan': '计划',
  'bi.device': '设备',
  'bi.record': '记录',

  'bi.time': '时间',
  'bi.no': '序号',

  'bi.record.trend': '数据流量趋势',
  'bi.record.average': '平均流量',
  'bi.record.upload': '上传流量',
  'bi.record.unit': '条',

  'bi.record.list': '数据流量明细',
  'bi.record.': '数据流量明细',

  'bi.qualified': '合格',
  'bi.unqualified': '漏巡',
  'bi.todo': '待巡',

  'bi.qualified.rate': '合格率',
  'bi.unqualified.rate': '漏巡率',

  'bi.rate.today': '今日合格率统计',
  'bi.attendacne.rank': '出勤率排行榜',
  'bi.rate.trend': '合格率趋势',

  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  sunday: '星期日',

  'bi.empty': '暂无数据',

  'bi.largest.guide': '请手动调整浏览器窗口至最大化以达到更好的大屏显示效果',
  'bi.largest.ignore': '直接进入',
};
