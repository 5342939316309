export default {
  'event.title': "Configuration de l'événement",
  'event.btn.add': 'Ajouter un évènement',
  'event.btn.import': 'Importer un événement',
  'event.btn.export': "Exporter l'événement",
  'event.table.code': "ID d'événement",
  'event.table.name': "Nom de l'événement",
  'event.table.simple': 'Événement simple',
  'event.table.common': 'Événement commun',
  'event.input.code.placeholder': "Veuillez saisir l'ID de l'événement",
  'event.input.name.placeholder': "Veuillez saisir le nom de l'événement",
  'event.table.moveup': 'Vers le haut',
  'event.table.movedown': 'Déplacer vers le bas',
};
