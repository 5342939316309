export default {
  'enclosure.title': '電子圍欄',
  'enclosure.table.name': '圍欄名稱',
  'enclosure.table.name.rule': '請輸入圍欄名稱',
  'enclosure.table.timeRange': '時間範圍',
  'enclosure.input.timeRange.add': '添加時間',
  'enclosure.btn.add': '增加圍欄',
  'enclosure.warn.updateAndNotSave': '圍欄修改後未保存設置，是否仍關閉？',
  'enclosure.btn.clear': '清空圍欄',
  'enclosure.clear.confirm': '確定要清空圍欄數據嗎？',
  'enclosure.btn.del': '刪除圍欄',
  'enclosure.del.confirm': '確定要刪除圍欄數據嗎？',
  'enclosure.btn.currentSite': '當前位置',
  'enclosure.table.btn.fence': '圍欄',
  'enclosure.warn.timeRange.coincide': '時間範圍重合，請重新設置',
  'enclosure.warn.timeRange.empty': '請選擇時間',
  'enclosure.warn.timeRange.notCheck': '時間段不能爲空',
};
