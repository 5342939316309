export default {
  'login.title': '歡迎',
  'login.subtitle': '歡迎使用智能巡檢管理系統',
  'login.type.login': '平臺登錄',
  'login.type.trial': '試用申請',
  'login.companyCode': '企業編碼',
  'login.companyCode.required': '請輸入企業編碼',
  'login.userName': '用戶名',
  'login.userName.required': '請輸入用戶名',
  'login.password': '密碼',
  'login.password.required': '請輸入密碼',
  'login.captcha': '驗證碼',
  'login.captcha.captchaRequired': '請輸入驗證碼',
  'login.remember': '記住密碼',
  'login.guest': '訪客模式',
  'login.submit': '登錄',
  'login.submitapplicaiton': '提交',
  'login.captcha.required': '請拖動滑塊完成人機驗證',
  'login.trial.companyName': '企業名稱',
  'login.trial.companyName.required': '請輸入企業名稱',
  'login.trial.equipment.read': '讀取設備號',
  'login.trial.equipment': '設備信息',
  'login.trial.equipment.required': '請讀取設備信息',
  'login.trial.contacts': '聯絡人姓名',
  'login.trial.contacts.required': '請輸入聯絡人姓名',
  'login.trial.contacts.phone': '聯絡人電話',
  'login.trial.contacts.phone.required': '請輸入聯絡人電話',
  'login.trial.captcha': '短信驗證碼',
  'login.trial.captcha.required': '請輸入驗證碼',
  'login.trial.captcha.read': '獲取驗證碼',
  'login.trial.city': '所在城市',
  'login.trial.city.required': '請選擇所在城市',
  'login.trial.address': '公司地址',
  'login.trial.address.en': '公司地址',
  'login.trial.address.required': '請輸入公司地址',
  'login.trial.countryCode': '國家代碼',
  'login.trial.timeZone': '時區',
  'login.trial.success': '申請成功',
  'login.trial.contacts.email': '郵箱',
  'login.trial.contacts.email.required': '請輸入郵箱',
  'login.trial.alarm.mobile': '驗證碼已發送至您的手機，請注意查收',
  'login.trial.alarm.email': '驗證碼已發送至您的郵箱，請注意查收',
  'login.trail.alarm.email.not.receive': '無法收到郵件？',
  'login.trial.alarm.email.spam':
    '如果長時間未收到郵件，可能是出於安全策略的問題被你的郵件服務器錯誤攔截，請去垃圾箱裏查看...',
  'login.trial.alarm.reg.mobile': '帳號信息已發送至您的手機，請注意查收',
  'login.trial.alarm.reg.email': '帳號信息已發送至您的郵箱，請注意查收',
  'login.trial.captcha.mobile.format': '手機號格式不正確，請重新輸入',
  'login.trial.contacts.email.format': '郵箱號格式不正確，請重新輸入',
  'login.trial.contacts.mobile.legal': '不合法的手機格式',
  'login.trial.contacts.email.legal': '不合法的郵箱格式',

  'login.app.title': '雲巡檢+ App',
  'login.app.subTitle': '「 巡檢管理，體驗更 + 」',
  'login.app.desc1': '掃碼左側二維碼下載App',
  'login.app.desc2': '掃描右側二維碼登錄App',
  'login.app.desc3': '使用平臺的用戶名密碼進行登錄',

  'login.slogan': '專注巡檢{year}年',
  'login.vaptcha.text': '人機驗證插件初始化中...',

  'login.nocaptcha.loading': '加載中...',
  'login.nocaptcha.guide': '按住滑塊，拖動到最右側',
  'login.nocaptcha.pass': '驗證通過',
  'login.nocaptcha.error': '哎呀，出錯了，點擊<a href="javascript:__nc.reset()">刷新</a>再來一次',
  'login.nocaptcha.offline': '網路不給力，請<a href="javascript:__nc.reset()">點擊刷新</a>',

  'login.expired.exit.desc': '登錄已過期，請重新登錄...',

  'login.companyCode.illegal': '非法的企業編碼',
  'login.retrievePwd': '找回密碼',
};
