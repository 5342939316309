export default {
  'module.1': 'Ρύθμιση Περιπολίας',
  'module.101': 'Ρύθμιση Εταιρίας',
  'module.10101': 'Προσθήκη Εταιρίας',
  'module.10102': 'Επεξεργασία Εταρίας',
  'module.10103': 'Διαγραφή Εταιρίας',
  'module.10104': 'Εμφάνιση Εταιρίας',
  'module.102': 'Ρύθμιση Συμβάντων',
  'module.10201': 'Προσθήκη Συμβάντων',
  'module.10202': 'ΕπεξεργασίαΣυμβάντος',
  'module.10203': 'ΔιαγραφήΣυμβάντων',
  'module.10204': 'Δείτε το μενού',
  'module.10205': 'Εισαγωγή Συμβάντος',
  'module.10206': 'Εξαγωγή Συμβάντος',
  'module.10207': 'Μαζική ανάγνωση',
  'module.103': 'Ρύθμιση Φύλακα',
  'module.10301': 'Προσθήκη Φύλακα',
  'module.10302': 'Επεξεργασία Φύλακα',
  'module.10303': 'Διαγραφή Φύλακα',
  'module.10304': 'Δείτε το μενού',
  'module.10305': 'Εισαγωγή Φύλακα',
  'module.10306': 'Εξαγωγή Φύλακα',
  'module.10307': 'Μαζική ανάγνωση',
  'module.10308': 'Συλλογή Δακτ.Αποτυπωμάτων',
  'module.10309': 'Επεξεργασία του δακτυλικού αποτυπώματος',
  'module.10310': 'Λήψη δακτ.αποτυπωμάτων',
  'module.104': 'Ρύθμιση σημείων ελέγχου',
  'module.10401': 'Προσθήκη Σημείο Ελέγχου',
  'module.10402': 'Επεξεργασία',
  'module.10403': 'Διαγραφή Σημείων Ελέγχου',
  'module.10404': 'Δείτε το μενού',
  'module.10405': 'Εισαγωγή Σημείου Ελέγχου',
  'module.10406': 'Λήψη δεδομένων',
  'module.10407': 'Μαζική ανάγνωση',
  'module.10408': 'Αντιστοίχιση περιεχομένου',
  'module.10409': 'Εξαγωγή Σημείου Ελέγχου',
  'module.105': 'Ρύθμιση Πλάνου',
  'module.10501': 'Προσθήκη Πλάνου',
  'module.10502': 'ΕπεξεργασίαΠλάνου',
  'module.10503': 'ΔιαγραφήΠλάνου',
  'module.10504': 'Δείτε το μενού',
  'module.10505': 'Αντιστοίχηση συσκευής',
  'module.10506': 'Εξαγωγή Πλάνου',
  'module.106': 'Επιθεωρήστε την εγγραφή συσκευής',
  'module.10601': 'Εγγραφή συσκευής',
  'module.10602': 'Διαγραφή της συσκευής',
  'module.10604': 'Δείτε το μενού',
  'module.107': 'Ρύθμιση Επαφών',
  'module.10701': 'Προσθέστε επαφές',
  'module.10702': 'Επεξεργασία',
  'module.10703': 'Διαγραφή',
  'module.10704': 'Δείτε το μενού',
  'module.10706': 'Εισαγωγή Επαφής',
  'module.10705': 'Εξαγωγή επαφής',
  'module.108': 'Πληροφορίες παραλαβής',
  'module.10801': 'Προσθήκη αποδειξης παραλαβής',
  'module.10802': 'Επεξεργασία των πληροφοριών απόδειξης',
  'module.10803': 'Διαγραφή των πληροφοριών απόδειξης',
  'module.10804': 'Δείτε το μενού',
  'module.10805': 'Εισαγωγή απόδειξης παραλαβής',
  'module.10806': 'Εξαγωγή απόδειξης παραλαβής',
  'module.109': 'Λήψη δεδομένων',
  'module.10901': 'Λήψη δεδομένων',
  'module.10904': 'Δείτε το μενού',
  'module.110': 'Ρύθμιση περιεχομένου',
  'module.11001': 'Προσθήκη Περιεχομένων',
  'module.11002': 'Επεξεργασίαπεριεχομένου',
  'module.11003': 'Διαγραφήπεριεχομένου',
  'module.11004': 'Δείτε το μενού',
  'module.11005': 'Επιλογή Σημείου Ελέγχου',
  'module.111': 'Ρύθμιση Walkie-talkie',
  'module.11101': 'Προσθήκη Ομάδας',
  'module.11102': 'ΕπεξεργασίαΟμάδας',
  'module.11103': 'ΔιαγραφήΟμάδας',
  'module.11104': 'Δείτε το μενού',
  'module.112': 'Ρύθμιση φωνητικών μηνυμάτων',
  'module.11204': 'Δείτε το μενού',
  'module.113': 'Λήψη δεδομένων',
  'module.11301': 'Λήψη δεδομένων',
  'module.11304': 'Δείτε το μενού',
  'module.114': 'Ηλεκτρονικός φράκτης',
  'module.11401': 'Προστέθηκαν φράχτες',
  'module.11402': 'Επεξεργαστείτε το φράχτη',
  'module.11403': 'Διαγράψτε το φράχτη',
  'module.11404': 'Δείτε το μενού',
  'module.2': 'Βασική Λειτουργία',
  'module.201': 'Ανάγνωση δεδομένων',
  'module.20104': 'Ανάγνωση δεδομένων',
  'module.202': 'Δεδομένα Ιστορικού',
  'module.20201': 'Εξαγωγή δεδομένων ιστορικού',
  'module.20203': 'Επανανάλυση',
  'module.20204': 'Δείτε το μενού',
  'module.203': 'Αναφορά περιπολίας',
  'module.20301': 'Εξαγωγή της έκθεσης επιθεώρησης',
  'module.20304': 'Δείτε το μενού',
  'module.204': 'Στατιστικά διαγράμματα',
  'module.20401': 'Στατιστικές εξαγωγών',
  'module.20404': 'Δείτε το μενού',
  'module.205': 'Δεδομένα παράλλειψης',
  'module.20501': 'Εξαγωγή δεδομένων εντοπισμού που λείπουν',
  'module.20504': 'Δείτε το μενού',
  'module.206': 'Δεδομένα συναγερμών',
  'module.20601': 'Εξαγωγή δεδομένων συναγερμού',
  'module.20603': 'Διαγραφή των δεδομένων ειδοποίησης',
  'module.20604': 'Δείτε το μενού',
  'module.207': 'Ιστορικό πληροφοριών',
  'module.20701': 'Εξαγωγή δεδομένων αρχείου καταγραφής',
  'module.20704': 'Δείτε το μενού',
  'module.208': 'Καθαρισμός δεδομένων συσκευής',
  'module.20804': 'Καθαρισμός δεδομένων συσκευής',
  'module.209': 'Ρύθμιση ώρας συσκευής',
  'module.20904': 'Ρύθμιση ώρας συσκευής',
  'module.210': 'Αφαίρεση δακτ.αποτυπωμάτων',
  'module.21004': 'Αφαίρεση δακτ.αποτυπωμάτων',
  'module.211': 'Παρακολούθηση',
  'module.21101': 'Επεξεργασία του σημείου',
  'module.21102': 'Αναπαραγωγή κομματιού',
  'module.21104': 'Δείτε το μενού',
  'module.21105': 'Προβολή δεδομένων σε πραγματικό χρόνο',
  'module.212': 'Αρχικά δεδομένα',
  'module.21201': 'Εξαγωγή ανεπεξέργαστων δεδομένων',
  'module.21202': 'Άγνωστη μετατροπή κάρτας',
  'module.21204': 'Δείτε το μενού',
  'module.213': 'Ημερολόγιο Περιπολιών',
  'module.21304': 'Δείτε το μενού',
  'module.214': 'Δεδομένα παρουσίας',
  'module.21401': 'Εξαγωγή δεδομένων συμμετοχής',
  'module.21404': 'Δείτε το μενού',
  'module.215': 'Λεπτομερής Αναφορά',
  'module.21501': 'Στοιχεία ελέγχου εξαγωγής',
  'module.21504': 'Δείτε το μενού',
  'module.3': 'Συντήρηση δεδομένων',
  'module.302': 'Λίστα συσκευών',
  'module.30201': 'Ενεργοποίηση συσκευής',
  'module.30202': 'Προσθήκη συσκευής',
  'module.30203': 'Διαγραφή της συσκευής',
  'module.30204': 'Δείτε το μενού',
  'module.30205': 'Αντικατάσταση συσκευής',
  'module.30206': 'Ρυθμίσεις παραμέτρων',
  'module.30207': 'Σχόλιο συσκευής',
  'module.303': 'Ρυθμίσεις συστήματος',
  'module.30304': 'Ρυθμίσεις συστήματος',
  'module.304': 'Ρυθμίσεις συσκευής',
  'module.30408': 'Ρύθμιση ρολογιού',
  'module.30404': 'Προβολή των ρυθμίσεων παραμέτρων συσκευής',
  'module.305': 'Ρυθμίσεις επικοινωνίας',
  'module.30504': 'Προβολή των ρυθμίσεων παραμέτρων επικοινωνίας',
  'module.306': 'Ρυθμίσεις δικαιωμάτων',
  'module.30601': 'Προσθήκη Ρόλου',
  'module.30602': 'ΕπεξεργασίαΡόλου',
  'module.30603': 'ΔιαγραφήΡόλου',
  'module.30604': 'Δείτε το μενού',
  'module.307': 'Ρύθμιση χειριστών',
  'module.30701': 'Προσθήκη Χειριστή',
  'module.30702': 'Επεξεργασίαχειριστή',
  'module.30703': 'Διαγραφήχειριστή',
  'module.30704': 'Δείτε το μενού',
  'module.30705': 'Εμφάνιση Εταιρίας',
  'module.30706': 'Εκχώρηση πρόσβασης',
  'module.30708': 'Αλλαγή κωδικού',
  'module.308': 'Αρχικοποίηση συσκευής',
  'module.30804': 'Αρχικοποίηση συσκευής',
  'module.5': 'πίνακας οργάνων',
  'module.216': 'BI',
  'module.21604': 'Δείτε το μενού',
};
