export default {
  'enclosure.title': 'Ηλεκτρονικός φράκτης',
  'enclosure.table.name': 'Το όνομα του φράχτη',
  'enclosure.table.name.rule': 'Παρακαλώ εισάγετε ένα όνομα φράχτη',
  'enclosure.table.timeRange': 'Χρονικό πλαίσιο',
  'enclosure.input.timeRange.add': 'Προσθήκη χρόνου',
  'enclosure.btn.add': 'Προσθήκη φράχτη',
  'enclosure.warn.updateAndNotSave':
    'Ο φράκτης εξακολουθεί να είναι κλειστός εάν οι ρυθμίσεις δεν αποθηκευτούν μετά την τροποποίησή τους;',
  'enclosure.btn.clear': 'Αδειάστε το φράχτη',
  'enclosure.clear.confirm': 'Είστε βέβαιοι ότι θέλετε να αδειάσετε τα δεδομένα φράχτη;',
  'enclosure.btn.del': 'Διαγράψτε το φράχτη',
  'enclosure.del.confirm': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε δεδομένα φράχτη;',
  'enclosure.btn.currentSite': 'Είστε εδώ',
  'enclosure.table.btn.fence': 'ξιφασκία',
  'enclosure.warn.timeRange.coincide': 'Το χρονικό εύρος συμπίπτει, ρυθμίστε το ξανά',
  'enclosure.warn.timeRange.empty': 'Παρακαλώ επιλέξτε ώρα',
  'enclosure.warn.timeRange.notCheck': 'Η χρονική περίοδος δεν μπορεί να είναι κενή',
};
