export default {
    'backup.plan.input.placeholder': '請輸入',
    'backup.input.styleErr': '格式錯誤',
    'backup.plan.title': '備份計劃',
    'backup.plan.add': '增加備份計劃',
    'backup.plan.edit': '編輯',
    'backup.plan.del': '刪除備份計劃',
    'backup.plan.table.name': '計劃名稱',
    'backup.plan.table.period': '週期類型',
    'backup.plan.table.periodValue': '週期數',
    'backup.plan.table.backupTime': '備份時間',
    'backup.plan.table.backupPath': '備份路徑',
    'backup.plan.table.reserveDay': '文件保留天數',
    'backup.table.operate': '操作',
    'backup.plan.input.backupPath.remark': '需提前創建，不輸入則使用默認位置',
    'backup.plan.period.day': '天',
    'backup.plan.period.week': '周',
    'backup.plan.period.month': '月',
    'backup.file.manual': '手動備份',
    'backup.file.upload': '導入備份',
    'backup.file.del': '刪除備份',
    'backup.file.download': '下載',
    'backup.file.restore': '恢復',
    'backup.file.table.fileName': '文件名稱',
    'backup.file.table.datasource': '數據來源',
    'backup.file.table.createTime': '備份時間',
    'backup.file.table.fileSize': '文件大小',
    'backup.file.manual.filePath': '文件存儲路徑',
    'backup.file.table.isSuccess': '操作結果',
    'backup.file.isSuccess.ok': '成功',
    'backup.file.isSuccess.fail': '失敗',
    'backup.file.restore.warnInfo': '重要提示：使用該文件恢復後，系統將清除所有數據並還原到該文件對應時期，請謹慎斟酌是否恢復？',
    'backup.file.restore.success.msg': '恢復{back}成功，請重新登錄系統',
    'backup.file.restore.auth': '恢復操作驗證',
    'backup.file.restore.authErr': '恢復操作驗證失敗',
    'backup.file.datasource.task': '定時備份',
    'backup.file.datasource.manual': '手動備份',
    'backup.file.datasource.import': '導入備份',
};