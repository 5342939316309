import {
  getStatisticOverview,
  getDepts,
  getChartBar,
  getChartRank,
  getLatestAlarms,
  getLatestHistories,
  getLatestMedias,
  getLatestHeatMaps,
  getLatestLogs,
  changePassWord,
  updateUserEmail,
} from '@/services/dashboard';
import i18n from '@/utils/i18n';
import { getProfile, setProfile } from '@/utils/profile';
import { message } from 'antd';

const initChartOverview = {
  barList: [],
  rankList: [],
  deptList: [],
};

const initLatestOverview = {
  latestAlarms: {
    trend: [],
    list: [],
  },
  latestHistories: {
    trend: [],
    list: [],
  },
  latestMedias: {
    trend: [],
    list: [],
  },
  latestHeatMap: [],
};

const initStatisticOverview = {
  total: 0,
  qualified: 0,
  unqualified: 0,
  todo: 0,
};

const initLatestHistories = {
  trend: [],
  lit: [],
};

const initLatestAlarms = {
  trend: [],
  list: [],
};

const initLatestLogs = [];

const initState = {
  statisticOverview: initStatisticOverview,
  chartOverview: initChartOverview,
  latestHistories: initLatestHistories,
  latestAlarms: initLatestAlarms,
  latestLogs: initLatestLogs,
  latestOverview: initLatestOverview,
};

const DashboardModel = {
  namespace: 'dashboard',
  state: initState,
  effects: {
    *fetchStatisticOverview(_, { call, put }) {
      const response = yield call(getStatisticOverview);
      yield put({
        type: 'saveStatisticOverview',
        payload: response,
      });
    },

    *fetchChartBar({ payload }, { call, put }) {
      const response = yield call(getChartBar, payload);
      yield put({
        type: 'saveChartBar',
        payload: response,
      });
    },

    *fetchChartRank({ payload }, { call, put }) {
      const response = yield call(getChartRank, payload);
      yield put({
        type: 'saveChartRank',
        payload: response,
      });
    },

    *fetchDept(_, { call, put }) {
      const response = yield call(getDepts);
      yield put({
        type: 'saveDept',
        payload: response,
      });
    },

    *fetchLatestAlarms({ payload }, { call, put }) {
      const response = yield call(getLatestAlarms, payload);
      yield put({
        type: 'saveLatestAlarms',
        payload: response,
      });
    },

    *fetchLatestHistories({ payload }, { call, put }) {
      const response = yield call(getLatestHistories, payload);
      // TODO: 这个时间范围居然是默认写死的10天？？
      yield put({
        type: 'saveLatestHistories',
        payload: response,
      });
    },

    *fetchLatestMedias(_, { call, put }) {
      const response = yield call(getLatestMedias);
      yield put({
        type: 'saveLatestMedias',
        payload: response,
      });
    },

    *fetchLatestHeatMaps(_, { call, put }) {
      const response = yield call(getLatestHeatMaps);
      yield put({
        type: 'saveLatestHeatMaps',
        payload: response,
      });
    },

    *fetchLatestLogs({ payload }, { call, put }) {
      const response = yield call(getLatestLogs, payload);
      yield put({
        type: 'saveLatestLogs',
        payload: response,
      });
    },

    *fetchPassWord({ payload }, { call }) {
      yield call(changePassWord, payload);
      message.success(i18n('header.password.modify.success'), 5, () => {
        window.location.href = '/login';
      });
    },

    *fetchUserEmail({ payload }, { call }) {
      yield call(updateUserEmail, payload);
      message.success(i18n('common.btn.success'), 5, () => {
        // 重写本地缓存profile里的数据
        const tmpProfileInfo = getProfile();
        const newProfileInfo = {
          ...tmpProfileInfo,
          email: payload.userEmailInfo,
        };
        setProfile(newProfileInfo);
      });
    },
  },
  reducers: {
    saveStatisticOverview(state, { payload }) {
      return {
        ...state,
        statisticOverview: payload,
      };
    },
    clearStatisticOverview(state) {
      return {
        ...state,
        statisticOverview: initStatisticOverview,
      };
    },

    saveChartBar(state, { payload }) {
      return {
        ...state,
        chartOverview: {
          ...state.chartOverview,
          barList: payload,
        },
      };
    },
    saveChartRank(state, { payload }) {
      return {
        ...state,
        chartOverview: {
          ...state.chartOverview,
          rankList: payload,
        },
      };
    },
    saveChartReport(state, { payload }) {
      return {
        ...state,
        chartOverview: {
          ...state.chartOverview,
          reportList: payload,
        },
      };
    },
    saveDept(state, { payload }) {
      return {
        ...state,
        chartOverview: {
          ...state.chartOverview,
          deptList: payload,
        },
      };
    },
    clearChartOverview(state) {
      return {
        ...state,
        chartOverview: initChartOverview,
      };
    },

    saveLatestAlarms(state, { payload }) {
      return {
        ...state,
        latestAlarms: {
          trend: [],
          list: payload,
        },
      };
    },

    clearLatestAlarms(state) {
      return {
        ...state,
        latestAlarms: initLatestAlarms,
      };
    },

    saveLatestHistories(state, { payload }) {
      return {
        ...state,
        latestHistories: payload,
      };
    },

    clearLatestHistories(state) {
      return {
        ...state,
        latestHistories: initLatestHistories,
      };
    },

    saveLatestMedias(state, { payload }) {
      return {
        ...state,
        latestOverview: {
          ...state.latestOverview,
          latestMedias: payload,
        },
      };
    },

    clearLatestMedias(state) {
      return {
        ...state,
        latestOverview: {
          ...state.latestOverview,
          latestMedias: initLatestOverview.latestMedias,
        },
      };
    },

    saveLatestHeatMaps(state, { payload }) {
      return {
        ...state,
        latestOverview: {
          ...state.latestOverview,
          latestHeatMap: payload,
        },
      };
    },
    clearLatestHeatMaps(state) {
      return {
        ...state,
        latestOverview: {
          ...state.latestOverview,
          latestHeatMap: initLatestOverview.latestHeatMap,
        },
      };
    },

    saveLatestLogs(state, { payload }) {
      return {
        ...state,
        latestLogs: payload,
      };
    },
    clearLatestLogs(state) {
      return {
        ...state,
        latestLogs: initLatestLogs,
      };
    },
  },
};
export default DashboardModel;
