const InteractionModel = {
  namespace: 'interaction',
  state: {
    interactionStatus: false,
  },
  effects: {},
  reducers: {
    setInteractionStatus(state, { payload }) {
      return {
        ...state,
        interactionStatus: payload,
      };
    },
  },
};
export default InteractionModel;
