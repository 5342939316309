export default {
  'content.title': 'Чек листы',
  'content.btn.add': 'Добавить',
  'content.btn.site': 'Присвоить метки',
  'content.btn.choice': 'Добавить вариант',
  'content.label.choice': 'Дополнительный контент',
  'content.input.content.placeholder': 'Пожалуйста, введите название контента',
  'content.input.des.placeholder': 'Пожалуйста, введите описание контента',
  'content.input.choice.placeholder': 'Пожалуйста, введите содержание опции',
  'content.notification.description':
    'Содержимое этой опции уже существует и не может быть добавлено повторно',
  'content.notification.choice.blank':
    'когда формат представления является одним или несколькими, необходимо ввести параметры содержимого!',
  'content.table.moveup': 'Вверх.',
  'content.table.movedown': 'Вниз!',
};
