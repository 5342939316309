export default {
    'backup.plan.input.placeholder': 'Please enter',
    'backup.input.styleErr': 'Formatting error',
    'backup.plan.title': 'Backup plan',
    'backup.plan.add': 'Adding a Backup plan',
    'backup.plan.edit': 'edit',
    'backup.plan.del': 'Deleting a Backup plan',
    'backup.plan.table.name': 'Project name',
    'backup.plan.table.period': 'Cycle type',
    'backup.plan.table.periodValue': 'Number of cycles',
    'backup.plan.table.backupTime': 'Backup time',
    'backup.plan.table.backupPath': 'Backup path',
    'backup.plan.table.reserveDay': 'Retention days',
    'backup.table.operate': 'operation',
    'backup.plan.input.backupPath.remark': 'This parameter must be created in advance. If you do not enter this parameter, use the default location',
    'backup.plan.period.day': 'day',
    'backup.plan.period.week': 'weeks',
    'backup.plan.period.month': 'month',
    'backup.file.manual': 'Manual backup',
    'backup.file.upload': 'Import backup',
    'backup.file.del': 'Delete a backup',
    'backup.file.download': 'download',
    'backup.file.restore': 'restore',
    'backup.file.table.fileName': 'File name',
    'backup.file.table.datasource': 'Data source',
    'backup.file.table.createTime': 'Backup time',
    'backup.file.table.fileSize': 'File size',
    'backup.file.manual.filePath': 'File storage path',
    'backup.file.table.isSuccess': 'Operation result',
    'backup.file.isSuccess.ok': 'success',
    'backup.file.isSuccess.fail': 'failure',
    'backup.file.restore.warnInfo': 'Important note: After using the file recovery, the system will clear all data and restore to the corresponding period of the file, please carefully consider whether to restore?',
    'backup.file.restore.success.msg': 'Restore {back} successfully, please log in again',
    'backup.file.restore.auth': 'Recovery operation verification',
    'backup.file.restore.authErr': 'Failed to verify the restore operation',
    'backup.file.datasource.task': 'Timed backup',
    'backup.file.datasource.manual': 'Manual backup',
    'backup.file.datasource.import': 'Import backup',
};