export default {
  'enclosure.title': 'Электронное ограждение',
  'enclosure.table.name': 'Название забора',
  'enclosure.table.name.rule': 'Введите имя забора',
  'enclosure.table.timeRange': 'Диапазон времени',
  'enclosure.input.timeRange.add': 'Добавьте время.',
  'enclosure.btn.add': 'Увеличить ограждение.',
  'enclosure.warn.updateAndNotSave': 'Ограда была изменена и несохранена. Она все еще закрыта?',
  'enclosure.btn.clear': 'Освободите ограждение.',
  'enclosure.clear.confirm': 'Вы уверены, что хотите очистить данные забора?',
  'enclosure.btn.del': 'Удали ограждение.',
  'enclosure.del.confirm': 'Уверен, что хочешь удалить данные забора?',
  'enclosure.btn.currentSite': 'Текущее положение.',
  'enclosure.table.btn.fence': 'забор',
  'enclosure.warn.timeRange.coincide': 'Временное сближение, пожалуйста, перенастройте',
  'enclosure.warn.timeRange.empty': 'Пожалуйста, выберите время.',
  'enclosure.warn.timeRange.notCheck': 'Время не должно быть пустым',
};
