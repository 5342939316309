export default {
    'backup.plan.input.placeholder': 'Παρακαλώ εισάγετε',
    'backup.input.styleErr': 'Σφάλμα μορφοποίησης',
    'backup.plan.title': 'Σχέδιο δημιουργίας αντιγράφων ασφαλείας',
    'backup.plan.add': 'Αυξήστε το σχέδιο δημιουργίας αντιγράφων ασφαλείας',
    'backup.plan.edit': 'επεξεργασία',
    'backup.plan.del': 'Διαγράψτε το σχέδιο δημιουργίας αντιγράφων ασφαλείας',
    'backup.plan.table.name': 'Όνομα σχεδίου',
    'backup.plan.table.period': 'Τύπος περιόδου',
    'backup.plan.table.periodValue': 'Αριθμός κύκλων',
    'backup.plan.table.backupTime': 'Χρόνος δημιουργίας αντιγράφων ασφαλείας',
    'backup.plan.table.backupPath': 'Διαδρομή δημιουργίας αντιγράφων ασφαλείας',
    'backup.plan.table.reserveDay': 'Αριθμός ημερών διατήρησης αρχείων',
    'backup.table.operate': 'εργάζομαι',
    'backup.plan.input.backupPath.remark': 'Πρέπει να το δημιουργήσετε εκ των προτέρων και αν δεν το εισαγάγετε, χρησιμοποιείται η προεπιλεγμένη τοποθεσία',
    'backup.plan.period.day': 'ουρανός',
    'backup.plan.period.week': 'εβδομάδα',
    'backup.plan.period.month': 'φεγγάρι',
    'backup.file.manual': 'Μη αυτόματη δημιουργία αντιγράφων ασφαλείας',
    'backup.file.upload': 'Εισαγάγετε το αντίγραφο ασφαλείας',
    'backup.file.del': 'Διαγραφή του αντιγράφου ασφαλείας',
    'backup.file.download': 'Καταφόρτωση',
    'backup.file.restore': 'αναρρώνω',
    'backup.file.table.fileName': 'Όνομα αρχείου',
    'backup.file.table.datasource': 'Πηγές δεδομένων',
    'backup.file.table.createTime': 'Χρόνος δημιουργίας αντιγράφων ασφαλείας',
    'backup.file.table.fileSize': 'Μέγεθος αρχείου',
    'backup.file.manual.filePath': 'Η διαδρομή προς την αποθήκευση αρχείων',
    'backup.file.table.isSuccess': 'Το αποτέλεσμα της επέμβασης',
    'backup.file.isSuccess.ok': 'διαδέχομαι',
    'backup.file.isSuccess.fail': 'αποτυχαίνω',
    'backup.file.restore.warnInfo': 'Σημαντικό: Αφού χρησιμοποιήσετε αυτό το αρχείο για ανάκτηση, όλα τα δεδομένα θα διαγραφούν και θα αποκατασταθούν στην αντίστοιχη περίοδο του αρχείου ?',
    'backup.file.restore.success.msg': 'Αποκατάσταση της επιτυχίας {back}, συνδεθείτε ξανά στο σύστημα',
    'backup.file.restore.auth': 'Επαλήθευση λειτουργίας ανάκτησης',
    'backup.file.restore.authErr': 'Αποτυχία επαλήθευσης λειτουργίας ανάκτησης',
    'backup.file.datasource.task': 'Προγραμματισμένα αντίγραφα ασφαλείας',
    'backup.file.datasource.manual': 'Μη αυτόματη δημιουργία αντιγράφων ασφαλείας',
    'backup.file.datasource.import': 'Εισαγάγετε το αντίγραφο ασφαλείας',
};