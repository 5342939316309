export default {
  'role.title': '角色信息',
  'role.btn.add': '增加角色',
  'role.btn.save': '保存角色',
  'role.btn.home': '返回首页',
  'role.table.name': '角色名称',
  'role.table.operate': '操作',
  'role.table.edit': '编辑',
  'role.input.name.placeholder': '请输入角色名称',
  'role.notification.module.description': '请选择模块信息',
  'role.notification.role.description': '请选择角色信息',
};
