export default {
  'map.alarmcontentinfo.alarmdetail': '报警详情:',
  'map.alarmcontentinfo.unkonwdetail': '未知详情',
  'map.alarmcontentinfo.iknow': '处理',

  'map.devicecontentinfo.unkonwperson': '未知人员',
  'map.devicecontentinfo.track.timerange': '轨迹数据回放的时间范围',
  'map.devicecontentinfo.track.speed': '回放速度(km/h)',
  'map.devicecontentinfo.track.begin': '开始回放',
  'map.devicecontentinfo.track.splitTime': '分段间隔时间（分钟）',
  'map.devicecontentinfo.track.splitDistance': '分段间隔距离（米）',

  'map.floatingtoolbar.track.replay': '重新回放',
  'map.floatingtoolbar.track.pause': '暂停',
  'map.floatingtoolbar.track.resume': '继续',
  'map.floatingtoolbar.track.clear': '退出轨迹',
  'map.floatingtoolbar.track.resetlimit': '重置条件',

  'map.device.deviceno': '设备编号：',
  'map.search.nocondition.msg': '请输入检索条件！',
  'map.track.notime.msg': '请选择轨迹回放时间范围！',
  'map.track.empty': '无有效轨迹数据！',
  'map.site.sitename': '地点名称',
  'map.site.address': '地址',
  'map.module.title': '实时监控',
  'map.sitecontentinfon.unknowdept': '未知区域',
  'map.sitecontentinfon.unknowperson': '未知人员',
  'map.sitecontentinfon.actionhistory': '查看历史',
  'map.topcomponent.select.plan': '请选择计划',
  'map.topcomponent.device.onlinetime': '设备在线时长(单位：分钟)',
  'map.topcomponent.screen.full': '切换全屏',

  'map.realdata.table.deptname': '区域名称',
  'map.realdata.table.sitename': '地点名称',
  'map.realdata.table.guardname': '人员名称',
  'map.realdata.table.guardcode': '设备号码',
  'map.realdata.table.happentime': '巡检时间',

  'map.realdata.changepage.old': '切换到旧版地图页',
  'map.realdata.changepage.new': '切换到新版地图页',
  'map.realdata': '实时数据',

  'map.google.normal': '普通',
  'map.google.satellite': '卫星',

  'map.videomodal.title': '视频',
  'map.audiomodal.title': '音频',
  'map.button.close': '关闭',

  'map.playback': '轨迹回放',
  'map.playback.over': '轨迹回放结束',
  'map.playback.mile': '米',
  'map.playback.timespan': '轨迹回放时间段不能超过2天，请重新选择日期范围',

  'map.load.timeout': '地图加载异常，页面需要重新加载',

  'map.cluster.more': '更多坐标请放大地图查看',
  'map.site.add.confirm': '确定要将当前选择的计划下的点全部添加到地图中吗？',
  'map.site.del.confirm': '确定要将当前选择的计划下的点全部从地图中删除吗？',
  'map.site.del.info': '请选择一个计划进行操作',

  'map.planImg.warn.planNotImgMsg': '当前计划没有示意图，请上传',
  'map.planImg.upload.label': '导入图片',
  'map.planImg.warn.siteNotHaveFlag': '当前计划巡检点暂未标记',
  'map.planImg.warn.outOfRange': '标记位置超出示意图范围，请重新标记',
  'map.planImg.mode.update': '编辑模式',
  'map.planImg.btn.addFlag': '新增标记',
  'map.planImg.btn.clearFlagState': '取消标点状态',
  'map.planImg.btn.uptImg': '修改图片',
  'map.planImg.btn.delFlag': '删除标记',
};
