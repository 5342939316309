export default {
  // 表格
  'company.table.index': '序号',
  'company.table.buy.count': '购买数量',
  'company.table.remain.count': '剩余数量',
  'company.table.term': '使用期限',
  'company.table.buy.time': '购买时间',
  'company.table.status': '状态',
  'company.table.device.type': '设备类型',
  'company.table.operate': 'операция',
  'company.table.name': 'название компании',
  'company.table.code': 'код предприятия',
  'company.table.software.version': 'версия',
  'company.table.begin.date': 'время начала',
  'company.table.end.date': 'время отсечки',
  'company.table.sales.man': '销售人员',
  'company.table.device.model': 'тип оборудования',
  'company.table.device.count': 'количество оборудования',
  'company.table.expire.query': '到期查询',
  'company.table.date.filer': 'фильтр даты',
  // 企业
  'company.info.list': 'Список предприятий',
  'company.save': 'Сохранить',
  'company.yes': 'Да',
  'company.no': 'Нет',
  'company.edit': 'редактор',
  'company.version.all': '全部版本',
  'company.version.formal': 'официальное издание',
  'company.version.try': 'пробное издание',
  'company.version.demo': '演示版',
  'company.version.network': '网络注册版',
  'company.version.free': '免费版',
  'company.all': '全部企业',
  'company.soon.expire': '即将到期企业',
  'company.already.expire': '已经到期企业',
  'company.adding': 'Добавить...',
  'company.add.fail': 'Ошибка добавления предприятия',
  'company.changing': 'редактор...',
  'company.change.fail': 'Ошибка изменения предприятия',
  'company.btn.add': 'новое предприятие',
  'company.btn.card': '充值卡信息',
  'comany.btn.delete': 'Удалить предприятие',
  'company.delete.tips': 'Удалить выбранное предприятие?',
  'company.reset.password': 'сбросить пароль',
  'company.reset.password.select': 'Сначала выберите предприятие для сброса пароля',
  'company.reset.password.success': 'сбросить Пароль успешно',
  'company.renewal.customer': '续费客户信息',
  'company.register.device.all': '全部注册设备信息',
  'company.register.device.soon': '即将到期设备信息',
  'company.register.device.expire': '已经到期设备信息',
  'company.register.company.count': '当前注册企业数量',
  'company.register.soon.count': '即将到期企业数量',
  'company.register.expire.count': '已经到期企业数量',
  'company.register.device.all.count': '当前注册设备数量',
  'company.register.device.soon.count': '即将到期设备数量',
  'company.register.device.expire.count': '已经到期设备数量',
  'company.search': 'Найти',
  'company.reset': 'Сброс',
  'company.filter': '筛选',
  'company.recharge.device.count': '充值设备数量',
  'company.renewal.time': '续费时间',
  'company.renewal.duration': '续费时长',
  // 充值卡
  'company.card.day': '日',
  'company.card.month': '个月',
  'company.card.add.month': '月',
  'company.card.year': '年',
  'company.card.stop': '停用',
  'company.card.start': '启用',
  'company.card.delete.fail': '该充值卡已被使用，不能删除！',
  'company.card.delete.tips': '确定要删除选定的充值卡信息吗？',
  'company.card.add.fail': '新增充值卡失败',
  'company.card.info.setting': '充值卡信息设置',
  'company.card.pls.buy.count': '请输入购买数量',
  'company.card.pls.term': '请输入使用期限',
  'company.card.term.unit': '期限单位',
  'company.card.select.sale.device.model': 'Выберите режим устройства',
  'company.card.device.model.not': '企业设备型号为空，无法添加充值卡信息！',
  // 统计
  'company.device.type.statistics': '设备类型统计',
  'company.sales.man.statistics': '销售人员统计',
  'company.sales.device.statistics': '销售设备统计',
  'company.sales.device.count.statistics': '月销售设备数量统计',
  'company.sales.device.count': '销售设备数量',
  'company.new.customers.statistics': '新增客户统计',
  'company.customers.register.count': '客户注册数量',
  'company.new.customers.info.statistics': '新增客户信息统计',
  'company.system.access.statistics': '系统访问记录',
  'company.sales.man.info.statistics': '销售人员信息统计',
  'company.date': '日期',
  'company.login.count': '登录次数',
  'company.one.month.login.count': '过去一个月码云系统登录频次统计',
  // 新增企业
  'company.add.set': 'Информация о предприятии',
  'company.add.basics': '基础信息',
  'company.add.module.share':
    'оборудование для обхода сокровищ не может быть поделено с другими устройствами!',
  'company.add.country.code.fail': '国家代码输入不正确，请重新输入！',
  'company.add.pls.name': 'Введите название предприятия',
  'company.add.pls.code': 'Введите код предприятия',
  'company.add.pls.model': 'Тип входного устройства',
  'company.add.wrongful.code': 'незаконное коммерческое кодирование',
  'company.add.code.length': 'длина кода предприятия не может быть меньше четырех битов',
  'company.add.contact': 'контакт',
  'company.add.pls.contact': 'Введите контакт',
  'company.add.contact.tel': 'контактный телефон',
  'company.add.pls.contact.tel': 'Пожалуйста, введите телефон',
  'company.add.email': 'электронная почта',
  'company.add.pls.email': 'Введите адрес электронной почты',
  'company.add.bengin.time': 'время начала',
  'company.add.end.time': 'время отсечки',
  'company.add.province': '所在省份',
  'company.add.city': '所在城市',
  'company.add.pls.city': '请输入所在城市',
  'company.add.register.time': 'регистрация',
  'company.add.sale.device.model': 'тип оборудования',
  'company.add.phone.app.login': 'Σύνδεση στην εφαρμογή για κινητά',
  'company.add.configure': '配置信息',
  'company.add.read.type': 'режим считывания данных',
  'company.add.omit.push.func': 'толкающая функция',
  'company.add.create.user': '开通人员',
  'company.add.time.zone': 'местный часовой пояс',
  'company.add.time.zone.des': 'Пожалуйста, соблюдайте местный часовой пояс.',
  'company.add.country.code': '国家代码',
  'company.add.country.app': 'максимальный пользователь',
  'company.add.pls.country.code': '请输入国家代码',
  'company.add.show.device.model': '显示设备型号',
  'company.add.no.show.device.model': '不显示设备型号',
  'company.add.data.package': 'система пакетов',
  'company.add.base64.mode': 'Base64',
  'company.add.no.app.push.func': 'без АПП',
  'company.add.app.push.func': 'функция подачи конца APP',
  'company.add.charge.or.not': '是否收费',
  'company.add.no.charge': '不收费',
  'company.add.charge': '收费',
  'company.add.companycode.info':
    'Не удаётся использовать гостей или администраторов в качестве корпоративных кодов, пожалуйста, введите их снова!',
  // 基础数据信息
  'company.basics.info': '基础数据信息',
  'company.basics.dept.name': '区域名称',
  'company.basics.site.list': '地点列表',
  'company.basics.site.name': '地点名称',
  'company.basics.site.code': '地点卡号',
  'company.basics.site.delete.tips': '确定要删除选定的地点信息吗？',
  'company.basics.user.list': '人员列表',
  'company.basics.user.name': 'Сотрудник',
  'company.basics.user.code': '人员卡号',
  'company.basics.user.delete.tips': '确定要删除选定的人员信息吗？',
  'company.basics.event.list': '事件列表',
  'company.basics.event.name': '事件名称',
  'company.basics.event.code': '事件卡号',
  'company.basics.user.event.tips': '确定要删除选定的事件信息吗？',
  // 设备
  'company.device.change.end.time.success': '修改设备结束时间成功',
  'company.device.number': '设备号码',
  'company.device.end.time': '结束时间',
  'company.device.belonging.enterprise': '所属企业',
  'company.device.delete.info.tips': '确定要删除选定的设备信息吗？',
  'company.device.change.end.time.tips': '确定要修改注册设备结束时间？',
  'company.device.register.count': '设备注册数量',
  'company.device.register.info.statistics': '注册设备信息统计',
};
